/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CompanyAddressDTO } from '../models/company-address-dto';
import { ChildIdNamePairDTO } from '../models/child-id-name-pair-dto';
import { IdNamePairDTO } from '../models/id-name-pair-dto';
import { BillToCompanyDTO } from '../models/bill-to-company-dto';
import { DebtorAddressMatchingDTO } from '../models/debtor-address-matching-dto';
@Injectable({
  providedIn: 'root',
})
class CompanyAddressService extends __BaseService {
  static readonly GetPath = '/api/CompanyAddress';
  static readonly PostPath = '/api/CompanyAddress';
  static readonly GetCompanyAddressByIdPath = '/api/CompanyAddress/{id}';
  static readonly PutPath = '/api/CompanyAddress/{id}';
  static readonly DeletePath = '/api/CompanyAddress/{id}';
  static readonly GetV2Path = '/api/CompanyAddress/v2/addresses';
  static readonly SearchPath = '/api/CompanyAddress/search/{companyId}/{term}';
  static readonly SearchBillToV2Path = '/api/CompanyAddress/searchBilltoV2';
  static readonly CheckForMatchingRecordsPath = '/api/CompanyAddress/matches';
  static readonly SearchShipperPath = '/api/CompanyAddress/searchShipper/{companyId}/{term}';
  static readonly SearchConsigneePath = '/api/CompanyAddress/searchConsignee/{companyId}/{term}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Summary list of company addresses locations
   * @param Authorization Bearer {token}
   * @return OK
   */
  GetResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<CompanyAddressDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CompanyAddress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanyAddressDTO>>;
      })
    );
  }
  /**
   * Summary list of company addresses locations
   * @param Authorization Bearer {token}
   * @return OK
   */
  Get(Authorization: string): __Observable<Array<CompanyAddressDTO>> {
    return this.GetResponse(Authorization).pipe(
      __map(_r => _r.body as Array<CompanyAddressDTO>)
    );
  }

  /**
   * Create a new CompanyAddress location
   * @param params The `CompanyAddressService.PostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Created
   */
  PostResponse(params: CompanyAddressService.PostParams): __Observable<__StrictHttpResponse<CompanyAddressDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/CompanyAddress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyAddressDTO>;
      })
    );
  }
  /**
   * Create a new CompanyAddress location
   * @param params The `CompanyAddressService.PostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Created
   */
  Post(params: CompanyAddressService.PostParams): __Observable<CompanyAddressDTO> {
    return this.PostResponse(params).pipe(
      __map(_r => _r.body as CompanyAddressDTO)
    );
  }

  /**
   * CompanyAddress location detail
   * @param params The `CompanyAddressService.GetCompanyAddressByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return OK
   */
  GetCompanyAddressByIdResponse(params: CompanyAddressService.GetCompanyAddressByIdParams): __Observable<__StrictHttpResponse<CompanyAddressDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CompanyAddress/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyAddressDTO>;
      })
    );
  }
  /**
   * CompanyAddress location detail
   * @param params The `CompanyAddressService.GetCompanyAddressByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return OK
   */
  GetCompanyAddressById(params: CompanyAddressService.GetCompanyAddressByIdParams): __Observable<CompanyAddressDTO> {
    return this.GetCompanyAddressByIdResponse(params).pipe(
      __map(_r => _r.body as CompanyAddressDTO)
    );
  }

  /**
   * Update an existing CompanyAddress
   * @param params The `CompanyAddressService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  PutResponse(params: CompanyAddressService.PutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/CompanyAddress/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update an existing CompanyAddress
   * @param params The `CompanyAddressService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  Put(params: CompanyAddressService.PutParams): __Observable<null> {
    return this.PutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Soft-delete the resource
   * @param params The `CompanyAddressService.DeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  DeleteResponse(params: CompanyAddressService.DeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/CompanyAddress/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Soft-delete the resource
   * @param params The `CompanyAddressService.DeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  Delete(params: CompanyAddressService.DeleteParams): __Observable<null> {
    return this.DeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List of company addresses that can be filtered
   * @param params The `CompanyAddressService.GetV2Params` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `Status`:
   *
   * - `Name`:
   *
   * - `AddressType`:
   *
   * @return OK
   */
  GetV2Response(params: CompanyAddressService.GetV2Params): __Observable<__StrictHttpResponse<Array<ChildIdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.Name != null) __params = __params.set('Name', params.Name.toString());
    if (params.AddressType != null) __params = __params.set('AddressType', params.AddressType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CompanyAddress/v2/addresses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ChildIdNamePairDTO>>;
      })
    );
  }
  /**
   * List of company addresses that can be filtered
   * @param params The `CompanyAddressService.GetV2Params` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `Status`:
   *
   * - `Name`:
   *
   * - `AddressType`:
   *
   * @return OK
   */
  GetV2(params: CompanyAddressService.GetV2Params): __Observable<Array<ChildIdNamePairDTO>> {
    return this.GetV2Response(params).pipe(
      __map(_r => _r.body as Array<ChildIdNamePairDTO>)
    );
  }

  /**
   * Searches for CompanyAddress by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.SearchParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `companyId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return OK
   */
  SearchResponse(params: CompanyAddressService.SearchParams): __Observable<__StrictHttpResponse<Array<IdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CompanyAddress/search/${encodeURIComponent(String(params.companyId))}/${encodeURIComponent(String(params.term))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdNamePairDTO>>;
      })
    );
  }
  /**
   * Searches for CompanyAddress by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.SearchParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `companyId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return OK
   */
  Search(params: CompanyAddressService.SearchParams): __Observable<Array<IdNamePairDTO>> {
    return this.SearchResponse(params).pipe(
      __map(_r => _r.body as Array<IdNamePairDTO>)
    );
  }

  /**
   * Searches for company bill-to addresses by name.
   * @param params The `CompanyAddressService.SearchBillToV2Params` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `Status`:
   *
   * - `Name`:
   *
   * - `IncludeMasterDebtors`:
   *
   * @return OK
   */
  SearchBillToV2Response(params: CompanyAddressService.SearchBillToV2Params): __Observable<__StrictHttpResponse<Array<BillToCompanyDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.Name != null) __params = __params.set('Name', params.Name.toString());
    if (params.IncludeMasterDebtors != null) __params = __params.set('IncludeMasterDebtors', params.IncludeMasterDebtors.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CompanyAddress/searchBilltoV2`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BillToCompanyDTO>>;
      })
    );
  }
  /**
   * Searches for company bill-to addresses by name.
   * @param params The `CompanyAddressService.SearchBillToV2Params` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `Status`:
   *
   * - `Name`:
   *
   * - `IncludeMasterDebtors`:
   *
   * @return OK
   */
  SearchBillToV2(params: CompanyAddressService.SearchBillToV2Params): __Observable<Array<BillToCompanyDTO>> {
    return this.SearchBillToV2Response(params).pipe(
      __map(_r => _r.body as Array<BillToCompanyDTO>)
    );
  }

  /**
   * Searches for company bill-to addresses and debtors for potential matches. Returns a list of matching results
   * @param params The `CompanyAddressService.CheckForMatchingRecordsParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `Zip`:
   *
   * - `State`:
   *
   * - `Line2`:
   *
   * - `Line1`:
   *
   * - `City`:
   *
   * @return OK
   */
  CheckForMatchingRecordsResponse(params: CompanyAddressService.CheckForMatchingRecordsParams): __Observable<__StrictHttpResponse<Array<DebtorAddressMatchingDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.Zip != null) __params = __params.set('Zip', params.Zip.toString());
    if (params.State != null) __params = __params.set('State', params.State.toString());
    if (params.Line2 != null) __params = __params.set('Line2', params.Line2.toString());
    if (params.Line1 != null) __params = __params.set('Line1', params.Line1.toString());
    if (params.City != null) __params = __params.set('City', params.City.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CompanyAddress/matches`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DebtorAddressMatchingDTO>>;
      })
    );
  }
  /**
   * Searches for company bill-to addresses and debtors for potential matches. Returns a list of matching results
   * @param params The `CompanyAddressService.CheckForMatchingRecordsParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `Zip`:
   *
   * - `State`:
   *
   * - `Line2`:
   *
   * - `Line1`:
   *
   * - `City`:
   *
   * @return OK
   */
  CheckForMatchingRecords(params: CompanyAddressService.CheckForMatchingRecordsParams): __Observable<Array<DebtorAddressMatchingDTO>> {
    return this.CheckForMatchingRecordsResponse(params).pipe(
      __map(_r => _r.body as Array<DebtorAddressMatchingDTO>)
    );
  }

  /**
   * Searches for company shipper addresses by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.SearchShipperParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `companyId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return OK
   */
  SearchShipperResponse(params: CompanyAddressService.SearchShipperParams): __Observable<__StrictHttpResponse<Array<IdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CompanyAddress/searchShipper/${encodeURIComponent(String(params.companyId))}/${encodeURIComponent(String(params.term))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdNamePairDTO>>;
      })
    );
  }
  /**
   * Searches for company shipper addresses by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.SearchShipperParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `companyId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return OK
   */
  SearchShipper(params: CompanyAddressService.SearchShipperParams): __Observable<Array<IdNamePairDTO>> {
    return this.SearchShipperResponse(params).pipe(
      __map(_r => _r.body as Array<IdNamePairDTO>)
    );
  }

  /**
   * Searches for company consignee addresses by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.SearchConsigneeParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `companyId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return OK
   */
  SearchConsigneeResponse(params: CompanyAddressService.SearchConsigneeParams): __Observable<__StrictHttpResponse<Array<IdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CompanyAddress/searchConsignee/${encodeURIComponent(String(params.companyId))}/${encodeURIComponent(String(params.term))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdNamePairDTO>>;
      })
    );
  }
  /**
   * Searches for company consignee addresses by name. Returns CompanyAddressId and Name
   * @param params The `CompanyAddressService.SearchConsigneeParams` containing the following parameters:
   *
   * - `term`:
   *
   * - `companyId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return OK
   */
  SearchConsignee(params: CompanyAddressService.SearchConsigneeParams): __Observable<Array<IdNamePairDTO>> {
    return this.SearchConsigneeResponse(params).pipe(
      __map(_r => _r.body as Array<IdNamePairDTO>)
    );
  }
}

module CompanyAddressService {

  /**
   * Parameters for Post
   */
  export interface PostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: CompanyAddressDTO;
  }

  /**
   * Parameters for GetCompanyAddressById
   */
  export interface GetCompanyAddressByIdParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for Put
   */
  export interface PutParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: CompanyAddressDTO;
  }

  /**
   * Parameters for Delete
   */
  export interface DeleteParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for GetV2
   */
  export interface GetV2Params {

    /**
     * Bearer {token}
     */
    Authorization: string;
    Status?: 'Active' | 'Inactive';
    Name?: string;
    AddressType?: 'BillTo' | 'Shipper' | 'Consignee';
  }

  /**
   * Parameters for Search
   */
  export interface SearchParams {
    term: string;
    companyId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for SearchBillToV2
   */
  export interface SearchBillToV2Params {

    /**
     * Bearer {token}
     */
    Authorization: string;
    Status?: 'Active' | 'Inactive';
    Name?: string;
    IncludeMasterDebtors?: boolean;
  }

  /**
   * Parameters for CheckForMatchingRecords
   */
  export interface CheckForMatchingRecordsParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    Zip?: string;
    State?: string;
    Line2?: string;
    Line1?: string;
    City?: string;
  }

  /**
   * Parameters for SearchShipper
   */
  export interface SearchShipperParams {
    term: string;
    companyId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for SearchConsignee
   */
  export interface SearchConsigneeParams {
    term: string;
    companyId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }
}

export { CompanyAddressService }
