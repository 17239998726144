/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LoadSummaryDTO } from '../models/load-summary-dto';
import { LoadDTO } from '../models/load-dto';
import { LoadDriverSummaryDTO } from '../models/load-driver-summary-dto';
import { LoadAttachmentDTO } from '../models/load-attachment-dto';
import { UploadFileDTO } from '../models/upload-file-dto';
import { SendFileDTO } from '../models/send-file-dto';
@Injectable({
  providedIn: 'root',
})
class LoadService extends __BaseService {
  static readonly GetPath = '/api/Load';
  static readonly PostPath = '/api/Load';
  static readonly GetFilteredLoadsPath = '/api/Load/filter';
  static readonly GetByDriverPath = '/api/Load/ByDriver/{driverId}';
  static readonly ExportPath = '/api/Load/export';
  static readonly GetLoadByIdPath = '/api/Load/{id}';
  static readonly PutPath = '/api/Load/{id}';
  static readonly DeletePath = '/api/Load/{id}';
  static readonly GetDeletedLoadByIdPath = '/api/Load/deleted/{id}';
  static readonly GetForDriverPath = '/api/Load/GetForDriver/{id}/{driverId}';
  static readonly UndeletePath = '/api/Load/undelete/{loadId}';
  static readonly GetAttachmentsPath = '/api/Load/{loadId}/attachments';
  static readonly UploadAttachmentPath = '/api/Load/{loadId}/attachments';
  static readonly DownloadAttachmentPath = '/api/Load/attachments/{id}';
  static readonly DeleteAttachmentPath = '/api/Load/attachments/{id}';
  static readonly UploadAttachmentsAsPdfPath = '/api/Load/{loadId}/pdfattachments/{fileType}';
  static readonly SendAttachmentPath = '/api/Load/attachments/send';
  static readonly ValidateLoadForInvoicingPath = '/api/Load/{loadId}/invoice/validate';
  static readonly SendInvoiceAttachmentPath = '/api/Load/{loadId}/attachments/send/invoice';
  static readonly SendInvoiceAttachmentToLenderPath = '/api/Load/{loadId}/attachments/send/invoice/lender';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Summary list of loads
   * @param Authorization Bearer {token}
   * @return Collection of loads for tenant
   */
  GetResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<LoadSummaryDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Load`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LoadSummaryDTO>>;
      })
    );
  }
  /**
   * Summary list of loads
   * @param Authorization Bearer {token}
   * @return Collection of loads for tenant
   */
  Get(Authorization: string): __Observable<Array<LoadSummaryDTO>> {
    return this.GetResponse(Authorization).pipe(
      __map(_r => _r.body as Array<LoadSummaryDTO>)
    );
  }

  /**
   * Create a new load
   * @param params The `LoadService.PostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Created
   */
  PostResponse(params: LoadService.PostParams): __Observable<__StrictHttpResponse<LoadDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Load`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoadDTO>;
      })
    );
  }
  /**
   * Create a new load
   * @param params The `LoadService.PostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Created
   */
  Post(params: LoadService.PostParams): __Observable<LoadDTO> {
    return this.PostResponse(params).pipe(
      __map(_r => _r.body as LoadDTO)
    );
  }

  /**
   * Filtered Summary list of loads
   * - Blank or null values will be excluded from filter.
   * @param params The `LoadService.GetFilteredLoadsParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `workOrder`:
   *
   * - `statusNotEquals`:
   *
   * - `statusEquals`:
   *
   * - `shippingEnd`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shippingBegin`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shipperContains`:
   *
   * - `poNumber`:
   *
   * - `needsAttention`:
   *
   * - `loadIdEquals`:
   *
   * - `invoiceUtcEnd`: Date load was invoiced in UTC time
   *
   * - `invoiceUtcBegin`: Date load was invoiced in UTC time
   *
   * - `invoiceNumber`:
   *
   * - `driverIdEquals`:
   *
   * - `driverContains`:
   *
   * - `dispatcherContains`:
   *
   * - `deliveryEnd`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `deliveryBegin`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `customerContains`:
   *
   * - `consigneeContains`:
   *
   * - `accountingExported`:
   *
   * @return Filtered Collection of loads for tenant
   */
  GetFilteredLoadsResponse(params: LoadService.GetFilteredLoadsParams): __Observable<__StrictHttpResponse<Array<LoadSummaryDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.workOrder != null) __params = __params.set('workOrder', params.workOrder.toString());
    (params.statusNotEquals || []).forEach(val => {if (val != null) __params = __params.append('statusNotEquals', val.toString())});
    (params.statusEquals || []).forEach(val => {if (val != null) __params = __params.append('statusEquals', val.toString())});
    if (params.shippingEnd != null) __params = __params.set('shippingEnd', params.shippingEnd.toString());
    if (params.shippingBegin != null) __params = __params.set('shippingBegin', params.shippingBegin.toString());
    if (params.shipperContains != null) __params = __params.set('shipperContains', params.shipperContains.toString());
    if (params.poNumber != null) __params = __params.set('poNumber', params.poNumber.toString());
    if (params.needsAttention != null) __params = __params.set('needsAttention', params.needsAttention.toString());
    (params.loadIdEquals || []).forEach(val => {if (val != null) __params = __params.append('loadIdEquals', val.toString())});
    if (params.invoiceUtcEnd != null) __params = __params.set('invoiceUtcEnd', params.invoiceUtcEnd.toString());
    if (params.invoiceUtcBegin != null) __params = __params.set('invoiceUtcBegin', params.invoiceUtcBegin.toString());
    if (params.invoiceNumber != null) __params = __params.set('invoiceNumber', params.invoiceNumber.toString());
    (params.driverIdEquals || []).forEach(val => {if (val != null) __params = __params.append('driverIdEquals', val.toString())});
    if (params.driverContains != null) __params = __params.set('driverContains', params.driverContains.toString());
    if (params.dispatcherContains != null) __params = __params.set('dispatcherContains', params.dispatcherContains.toString());
    if (params.deliveryEnd != null) __params = __params.set('deliveryEnd', params.deliveryEnd.toString());
    if (params.deliveryBegin != null) __params = __params.set('deliveryBegin', params.deliveryBegin.toString());
    if (params.customerContains != null) __params = __params.set('customerContains', params.customerContains.toString());
    if (params.consigneeContains != null) __params = __params.set('consigneeContains', params.consigneeContains.toString());
    if (params.accountingExported != null) __params = __params.set('accountingExported', params.accountingExported.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Load/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LoadSummaryDTO>>;
      })
    );
  }
  /**
   * Filtered Summary list of loads
   * - Blank or null values will be excluded from filter.
   * @param params The `LoadService.GetFilteredLoadsParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `workOrder`:
   *
   * - `statusNotEquals`:
   *
   * - `statusEquals`:
   *
   * - `shippingEnd`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shippingBegin`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shipperContains`:
   *
   * - `poNumber`:
   *
   * - `needsAttention`:
   *
   * - `loadIdEquals`:
   *
   * - `invoiceUtcEnd`: Date load was invoiced in UTC time
   *
   * - `invoiceUtcBegin`: Date load was invoiced in UTC time
   *
   * - `invoiceNumber`:
   *
   * - `driverIdEquals`:
   *
   * - `driverContains`:
   *
   * - `dispatcherContains`:
   *
   * - `deliveryEnd`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `deliveryBegin`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `customerContains`:
   *
   * - `consigneeContains`:
   *
   * - `accountingExported`:
   *
   * @return Filtered Collection of loads for tenant
   */
  GetFilteredLoads(params: LoadService.GetFilteredLoadsParams): __Observable<Array<LoadSummaryDTO>> {
    return this.GetFilteredLoadsResponse(params).pipe(
      __map(_r => _r.body as Array<LoadSummaryDTO>)
    );
  }

  /**
   * Driver summary list of loads
   * @param params The `LoadService.GetByDriverParams` containing the following parameters:
   *
   * - `driverId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Collection of loads for driver
   */
  GetByDriverResponse(params: LoadService.GetByDriverParams): __Observable<__StrictHttpResponse<Array<LoadDriverSummaryDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Load/ByDriver/${encodeURIComponent(String(params.driverId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LoadDriverSummaryDTO>>;
      })
    );
  }
  /**
   * Driver summary list of loads
   * @param params The `LoadService.GetByDriverParams` containing the following parameters:
   *
   * - `driverId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Collection of loads for driver
   */
  GetByDriver(params: LoadService.GetByDriverParams): __Observable<Array<LoadDriverSummaryDTO>> {
    return this.GetByDriverResponse(params).pipe(
      __map(_r => _r.body as Array<LoadDriverSummaryDTO>)
    );
  }

  /**
   * Returns CSV file of data from GET /load/filter
   * Note: You can pass multiple parameters for StatusEquals
   * @param params The `LoadService.ExportParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `workOrder`:
   *
   * - `statusNotEquals`:
   *
   * - `statusEquals`:
   *
   * - `shippingEnd`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shippingBegin`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shipperContains`:
   *
   * - `poNumber`:
   *
   * - `needsAttention`:
   *
   * - `loadIdEquals`:
   *
   * - `invoiceUtcEnd`: Date load was invoiced in UTC time
   *
   * - `invoiceUtcBegin`: Date load was invoiced in UTC time
   *
   * - `invoiceNumber`:
   *
   * - `driverIdEquals`:
   *
   * - `driverContains`:
   *
   * - `dispatcherContains`:
   *
   * - `deliveryEnd`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `deliveryBegin`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `customerContains`:
   *
   * - `consigneeContains`:
   *
   * - `accountingExported`:
   *
   * @return A CSV file
   */
  ExportResponse(params: LoadService.ExportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.workOrder != null) __params = __params.set('workOrder', params.workOrder.toString());
    (params.statusNotEquals || []).forEach(val => {if (val != null) __params = __params.append('statusNotEquals', val.toString())});
    (params.statusEquals || []).forEach(val => {if (val != null) __params = __params.append('statusEquals', val.toString())});
    if (params.shippingEnd != null) __params = __params.set('shippingEnd', params.shippingEnd.toString());
    if (params.shippingBegin != null) __params = __params.set('shippingBegin', params.shippingBegin.toString());
    if (params.shipperContains != null) __params = __params.set('shipperContains', params.shipperContains.toString());
    if (params.poNumber != null) __params = __params.set('poNumber', params.poNumber.toString());
    if (params.needsAttention != null) __params = __params.set('needsAttention', params.needsAttention.toString());
    (params.loadIdEquals || []).forEach(val => {if (val != null) __params = __params.append('loadIdEquals', val.toString())});
    if (params.invoiceUtcEnd != null) __params = __params.set('invoiceUtcEnd', params.invoiceUtcEnd.toString());
    if (params.invoiceUtcBegin != null) __params = __params.set('invoiceUtcBegin', params.invoiceUtcBegin.toString());
    if (params.invoiceNumber != null) __params = __params.set('invoiceNumber', params.invoiceNumber.toString());
    (params.driverIdEquals || []).forEach(val => {if (val != null) __params = __params.append('driverIdEquals', val.toString())});
    if (params.driverContains != null) __params = __params.set('driverContains', params.driverContains.toString());
    if (params.dispatcherContains != null) __params = __params.set('dispatcherContains', params.dispatcherContains.toString());
    if (params.deliveryEnd != null) __params = __params.set('deliveryEnd', params.deliveryEnd.toString());
    if (params.deliveryBegin != null) __params = __params.set('deliveryBegin', params.deliveryBegin.toString());
    if (params.customerContains != null) __params = __params.set('customerContains', params.customerContains.toString());
    if (params.consigneeContains != null) __params = __params.set('consigneeContains', params.consigneeContains.toString());
    if (params.accountingExported != null) __params = __params.set('accountingExported', params.accountingExported.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Load/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Returns CSV file of data from GET /load/filter
   * Note: You can pass multiple parameters for StatusEquals
   * @param params The `LoadService.ExportParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `workOrder`:
   *
   * - `statusNotEquals`:
   *
   * - `statusEquals`:
   *
   * - `shippingEnd`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shippingBegin`: Shipping date is in local time. Do not convert to UTC or handle time zone
   *
   * - `shipperContains`:
   *
   * - `poNumber`:
   *
   * - `needsAttention`:
   *
   * - `loadIdEquals`:
   *
   * - `invoiceUtcEnd`: Date load was invoiced in UTC time
   *
   * - `invoiceUtcBegin`: Date load was invoiced in UTC time
   *
   * - `invoiceNumber`:
   *
   * - `driverIdEquals`:
   *
   * - `driverContains`:
   *
   * - `dispatcherContains`:
   *
   * - `deliveryEnd`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `deliveryBegin`: Delivery date is in local time. Do not convert to UTC or handle time zone
   *
   * - `customerContains`:
   *
   * - `consigneeContains`:
   *
   * - `accountingExported`:
   *
   * @return A CSV file
   */
  Export(params: LoadService.ExportParams): __Observable<Blob> {
    return this.ExportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Load detail
   * @param params The `LoadService.GetLoadByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The requested load
   */
  GetLoadByIdResponse(params: LoadService.GetLoadByIdParams): __Observable<__StrictHttpResponse<LoadDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Load/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoadDTO>;
      })
    );
  }
  /**
   * Load detail
   * @param params The `LoadService.GetLoadByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The requested load
   */
  GetLoadById(params: LoadService.GetLoadByIdParams): __Observable<LoadDTO> {
    return this.GetLoadByIdResponse(params).pipe(
      __map(_r => _r.body as LoadDTO)
    );
  }

  /**
   * Update an existing load
   * @param params The `LoadService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  PutResponse(params: LoadService.PutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Load/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update an existing load
   * @param params The `LoadService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  Put(params: LoadService.PutParams): __Observable<null> {
    return this.PutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Soft-delete the resource
   * @param params The `LoadService.DeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  DeleteResponse(params: LoadService.DeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Load/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Soft-delete the resource
   * @param params The `LoadService.DeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  Delete(params: LoadService.DeleteParams): __Observable<null> {
    return this.DeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get deleted load
   * @param params The `LoadService.GetDeletedLoadByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The requested load
   */
  GetDeletedLoadByIdResponse(params: LoadService.GetDeletedLoadByIdParams): __Observable<__StrictHttpResponse<LoadDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Load/deleted/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoadDTO>;
      })
    );
  }
  /**
   * Get deleted load
   * @param params The `LoadService.GetDeletedLoadByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The requested load
   */
  GetDeletedLoadById(params: LoadService.GetDeletedLoadByIdParams): __Observable<LoadDTO> {
    return this.GetDeletedLoadByIdResponse(params).pipe(
      __map(_r => _r.body as LoadDTO)
    );
  }

  /**
   * Load detail for a driver
   * @param params The `LoadService.GetForDriverParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `driverId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The requested load
   */
  GetForDriverResponse(params: LoadService.GetForDriverParams): __Observable<__StrictHttpResponse<LoadDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Load/GetForDriver/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.driverId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoadDTO>;
      })
    );
  }
  /**
   * Load detail for a driver
   * @param params The `LoadService.GetForDriverParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `driverId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The requested load
   */
  GetForDriver(params: LoadService.GetForDriverParams): __Observable<LoadDTO> {
    return this.GetForDriverResponse(params).pipe(
      __map(_r => _r.body as LoadDTO)
    );
  }

  /**
   * Undelete a load
   * @param params The `LoadService.UndeleteParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   */
  UndeleteResponse(params: LoadService.UndeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/Load/undelete/${encodeURIComponent(String(params.loadId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Undelete a load
   * @param params The `LoadService.UndeleteParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   */
  Undelete(params: LoadService.UndeleteParams): __Observable<null> {
    return this.UndeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get a list of attachments for the load
   * @param params The `LoadService.GetAttachmentsParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return OK
   */
  GetAttachmentsResponse(params: LoadService.GetAttachmentsParams): __Observable<__StrictHttpResponse<Array<LoadAttachmentDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Load/${encodeURIComponent(String(params.loadId))}/attachments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LoadAttachmentDTO>>;
      })
    );
  }
  /**
   * Get a list of attachments for the load
   * @param params The `LoadService.GetAttachmentsParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return OK
   */
  GetAttachments(params: LoadService.GetAttachmentsParams): __Observable<Array<LoadAttachmentDTO>> {
    return this.GetAttachmentsResponse(params).pipe(
      __map(_r => _r.body as Array<LoadAttachmentDTO>)
    );
  }

  /**
   * Upload a load attachment
   * @param params The `LoadService.UploadAttachmentParams` containing the following parameters:
   *
   * - `loadId`: Valid load id
   *
   * - `Authorization`: Bearer {token}
   *
   * - `fileType`: Valid options: Other, Invoice, WorkOrder, BOL, POD, RCS, SignedRCS, Dispatch
   *
   * - `file`: The upload
   *
   * @return Created
   */
  UploadAttachmentResponse(params: LoadService.UploadAttachmentParams): __Observable<__StrictHttpResponse<LoadAttachmentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.fileType != null) __params = __params.set('fileType', params.fileType.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Load/${encodeURIComponent(String(params.loadId))}/attachments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoadAttachmentDTO>;
      })
    );
  }
  /**
   * Upload a load attachment
   * @param params The `LoadService.UploadAttachmentParams` containing the following parameters:
   *
   * - `loadId`: Valid load id
   *
   * - `Authorization`: Bearer {token}
   *
   * - `fileType`: Valid options: Other, Invoice, WorkOrder, BOL, POD, RCS, SignedRCS, Dispatch
   *
   * - `file`: The upload
   *
   * @return Created
   */
  UploadAttachment(params: LoadService.UploadAttachmentParams): __Observable<LoadAttachmentDTO> {
    return this.UploadAttachmentResponse(params).pipe(
      __map(_r => _r.body as LoadAttachmentDTO)
    );
  }

  /**
   * Load attachment detail
   * @param params The `LoadService.DownloadAttachmentParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The requested load attachment
   */
  DownloadAttachmentResponse(params: LoadService.DownloadAttachmentParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Load/attachments/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Load attachment detail
   * @param params The `LoadService.DownloadAttachmentParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The requested load attachment
   */
  DownloadAttachment(params: LoadService.DownloadAttachmentParams): __Observable<Blob> {
    return this.DownloadAttachmentResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Soft-delete the resource
   * @param params The `LoadService.DeleteAttachmentParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  DeleteAttachmentResponse(params: LoadService.DeleteAttachmentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Load/attachments/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Soft-delete the resource
   * @param params The `LoadService.DeleteAttachmentParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  DeleteAttachment(params: LoadService.DeleteAttachmentParams): __Observable<null> {
    return this.DeleteAttachmentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Upload a load attachment as PDF by providing a list of images to be stitched together
   * @param params The `LoadService.UploadAttachmentsAsPdfParams` containing the following parameters:
   *
   * - `loadId`: Valid load id
   *
   * - `fileType`: Valid options: Other, Invoice, WorkOrder, BOL, POD, RCS, SignedRCS, Dispatch
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`: The uploaded files
   *
   * @return Created
   */
  UploadAttachmentsAsPdfResponse(params: LoadService.UploadAttachmentsAsPdfParams): __Observable<__StrictHttpResponse<LoadAttachmentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Load/${encodeURIComponent(String(params.loadId))}/pdfattachments/${encodeURIComponent(String(params.fileType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoadAttachmentDTO>;
      })
    );
  }
  /**
   * Upload a load attachment as PDF by providing a list of images to be stitched together
   * @param params The `LoadService.UploadAttachmentsAsPdfParams` containing the following parameters:
   *
   * - `loadId`: Valid load id
   *
   * - `fileType`: Valid options: Other, Invoice, WorkOrder, BOL, POD, RCS, SignedRCS, Dispatch
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`: The uploaded files
   *
   * @return Created
   */
  UploadAttachmentsAsPdf(params: LoadService.UploadAttachmentsAsPdfParams): __Observable<LoadAttachmentDTO> {
    return this.UploadAttachmentsAsPdfResponse(params).pipe(
      __map(_r => _r.body as LoadAttachmentDTO)
    );
  }

  /**
   * Sends attachments to email recipients
   * @param params The `LoadService.SendAttachmentParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  SendAttachmentResponse(params: LoadService.SendAttachmentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Load/attachments/send`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Sends attachments to email recipients
   * @param params The `LoadService.SendAttachmentParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  SendAttachment(params: LoadService.SendAttachmentParams): __Observable<null> {
    return this.SendAttachmentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Validates load is ready to be invoiced
   * @param params The `LoadService.ValidateLoadForInvoicingParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   */
  ValidateLoadForInvoicingResponse(params: LoadService.ValidateLoadForInvoicingParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Load/${encodeURIComponent(String(params.loadId))}/invoice/validate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Validates load is ready to be invoiced
   * @param params The `LoadService.ValidateLoadForInvoicingParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   */
  ValidateLoadForInvoicing(params: LoadService.ValidateLoadForInvoicingParams): __Observable<null> {
    return this.ValidateLoadForInvoicingResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Sends invoice to email recipients
   * @param params The `LoadService.SendInvoiceAttachmentParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  SendInvoiceAttachmentResponse(params: LoadService.SendInvoiceAttachmentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Load/${encodeURIComponent(String(params.loadId))}/attachments/send/invoice`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Sends invoice to email recipients
   * @param params The `LoadService.SendInvoiceAttachmentParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  SendInvoiceAttachment(params: LoadService.SendInvoiceAttachmentParams): __Observable<null> {
    return this.SendInvoiceAttachmentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Sends invoice email only to lender
   * @param params The `LoadService.SendInvoiceAttachmentToLenderParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  SendInvoiceAttachmentToLenderResponse(params: LoadService.SendInvoiceAttachmentToLenderParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Load/${encodeURIComponent(String(params.loadId))}/attachments/send/invoice/lender`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Sends invoice email only to lender
   * @param params The `LoadService.SendInvoiceAttachmentToLenderParams` containing the following parameters:
   *
   * - `loadId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  SendInvoiceAttachmentToLender(params: LoadService.SendInvoiceAttachmentToLenderParams): __Observable<null> {
    return this.SendInvoiceAttachmentToLenderResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module LoadService {

  /**
   * Parameters for Post
   */
  export interface PostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: LoadDTO;
  }

  /**
   * Parameters for GetFilteredLoads
   */
  export interface GetFilteredLoadsParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    workOrder?: string;
    statusNotEquals?: Array<'Open' | 'Assigned' | 'Dispatched' | 'InTransit' | 'InYard' | 'Delivered' | 'Claim' | 'Audited' | 'Invoiced' | 'Paid' | 'Archive' | 'Deleted'>;
    statusEquals?: Array<'Open' | 'Assigned' | 'Dispatched' | 'InTransit' | 'InYard' | 'Delivered' | 'Claim' | 'Audited' | 'Invoiced' | 'Paid' | 'Archive' | 'Deleted'>;

    /**
     * Shipping date is in local time. Do not convert to UTC or handle time zone
     */
    shippingEnd?: string;

    /**
     * Shipping date is in local time. Do not convert to UTC or handle time zone
     */
    shippingBegin?: string;
    shipperContains?: string;
    poNumber?: string;
    needsAttention?: boolean;
    loadIdEquals?: Array<number>;

    /**
     * Date load was invoiced in UTC time
     */
    invoiceUtcEnd?: string;

    /**
     * Date load was invoiced in UTC time
     */
    invoiceUtcBegin?: string;
    invoiceNumber?: number;
    driverIdEquals?: Array<number>;
    driverContains?: string;
    dispatcherContains?: string;

    /**
     * Delivery date is in local time. Do not convert to UTC or handle time zone
     */
    deliveryEnd?: string;

    /**
     * Delivery date is in local time. Do not convert to UTC or handle time zone
     */
    deliveryBegin?: string;
    customerContains?: string;
    consigneeContains?: string;
    accountingExported?: boolean;
  }

  /**
   * Parameters for GetByDriver
   */
  export interface GetByDriverParams {
    driverId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for Export
   */
  export interface ExportParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    workOrder?: string;
    statusNotEquals?: Array<'Open' | 'Assigned' | 'Dispatched' | 'InTransit' | 'InYard' | 'Delivered' | 'Claim' | 'Audited' | 'Invoiced' | 'Paid' | 'Archive' | 'Deleted'>;
    statusEquals?: Array<'Open' | 'Assigned' | 'Dispatched' | 'InTransit' | 'InYard' | 'Delivered' | 'Claim' | 'Audited' | 'Invoiced' | 'Paid' | 'Archive' | 'Deleted'>;

    /**
     * Shipping date is in local time. Do not convert to UTC or handle time zone
     */
    shippingEnd?: string;

    /**
     * Shipping date is in local time. Do not convert to UTC or handle time zone
     */
    shippingBegin?: string;
    shipperContains?: string;
    poNumber?: string;
    needsAttention?: boolean;
    loadIdEquals?: Array<number>;

    /**
     * Date load was invoiced in UTC time
     */
    invoiceUtcEnd?: string;

    /**
     * Date load was invoiced in UTC time
     */
    invoiceUtcBegin?: string;
    invoiceNumber?: number;
    driverIdEquals?: Array<number>;
    driverContains?: string;
    dispatcherContains?: string;

    /**
     * Delivery date is in local time. Do not convert to UTC or handle time zone
     */
    deliveryEnd?: string;

    /**
     * Delivery date is in local time. Do not convert to UTC or handle time zone
     */
    deliveryBegin?: string;
    customerContains?: string;
    consigneeContains?: string;
    accountingExported?: boolean;
  }

  /**
   * Parameters for GetLoadById
   */
  export interface GetLoadByIdParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for Put
   */
  export interface PutParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: LoadDTO;
  }

  /**
   * Parameters for Delete
   */
  export interface DeleteParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for GetDeletedLoadById
   */
  export interface GetDeletedLoadByIdParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for GetForDriver
   */
  export interface GetForDriverParams {
    id: number;
    driverId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for Undelete
   */
  export interface UndeleteParams {
    loadId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for GetAttachments
   */
  export interface GetAttachmentsParams {
    loadId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for UploadAttachment
   */
  export interface UploadAttachmentParams {

    /**
     * Valid load id
     */
    loadId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;

    /**
     * Valid options: Other, Invoice, WorkOrder, BOL, POD, RCS, SignedRCS, Dispatch
     */
    fileType?: 'Other' | 'Invoice' | 'InvoicePreview' | 'InvoicePacket' | 'Dispatch' | 'WorkOrder' | 'BOL' | 'POD' | 'RCS' | 'SignedRCS' | 'Claim';

    /**
     * The upload
     */
    file?: Blob;
  }

  /**
   * Parameters for DownloadAttachment
   */
  export interface DownloadAttachmentParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for DeleteAttachment
   */
  export interface DeleteAttachmentParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for UploadAttachmentsAsPdf
   */
  export interface UploadAttachmentsAsPdfParams {

    /**
     * Valid load id
     */
    loadId: number;

    /**
     * Valid options: Other, Invoice, WorkOrder, BOL, POD, RCS, SignedRCS, Dispatch
     */
    fileType: 'Other' | 'Invoice' | 'InvoicePreview' | 'InvoicePacket' | 'Dispatch' | 'WorkOrder' | 'BOL' | 'POD' | 'RCS' | 'SignedRCS' | 'Claim';

    /**
     * Bearer {token}
     */
    Authorization: string;

    /**
     * The uploaded files
     */
    body?: Array<UploadFileDTO>;
  }

  /**
   * Parameters for SendAttachment
   */
  export interface SendAttachmentParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: SendFileDTO;
  }

  /**
   * Parameters for ValidateLoadForInvoicing
   */
  export interface ValidateLoadForInvoicingParams {
    loadId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for SendInvoiceAttachment
   */
  export interface SendInvoiceAttachmentParams {
    loadId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: SendFileDTO;
  }

  /**
   * Parameters for SendInvoiceAttachmentToLender
   */
  export interface SendInvoiceAttachmentToLenderParams {
    loadId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: Array<number>;
  }
}

export { LoadService }
