/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BillingConfigDTO } from '../models/billing-config-dto';
import { BillingStatusDTO } from '../models/billing-status-dto';
import { BillingPortalDTO } from '../models/billing-portal-dto';
import { BillingSessionDTO } from '../models/billing-session-dto';
@Injectable({
  providedIn: 'root',
})
class BillingService extends __BaseService {
  static readonly ConfigPath = '/api/Billing/Config';
  static readonly StatusPath = '/api/Billing/Status';
  static readonly PortalPath = '/api/Billing/Portal';
  static readonly SubscribePath = '/api/Billing/Subscribe';
  static readonly CheckoutPath = '/api/Billing/Checkout';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets Stripe information
   * @param Authorization Bearer {token}
   * @return OK
   */
  ConfigResponse(Authorization: string): __Observable<__StrictHttpResponse<BillingConfigDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Billing/Config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BillingConfigDTO>;
      })
    );
  }
  /**
   * Gets Stripe information
   * @param Authorization Bearer {token}
   * @return OK
   */
  Config(Authorization: string): __Observable<BillingConfigDTO> {
    return this.ConfigResponse(Authorization).pipe(
      __map(_r => _r.body as BillingConfigDTO)
    );
  }

  /**
   * Gets tenant account subscription status
   * @param Authorization Bearer {token}
   * @return OK
   */
  StatusResponse(Authorization: string): __Observable<__StrictHttpResponse<BillingStatusDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Billing/Status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BillingStatusDTO>;
      })
    );
  }
  /**
   * Gets tenant account subscription status
   * @param Authorization Bearer {token}
   * @return OK
   */
  Status(Authorization: string): __Observable<BillingStatusDTO> {
    return this.StatusResponse(Authorization).pipe(
      __map(_r => _r.body as BillingStatusDTO)
    );
  }

  /**
   * Gets signed link to the Stripe portal
   * @param Authorization Bearer {token}
   * @return OK
   */
  PortalResponse(Authorization: string): __Observable<__StrictHttpResponse<BillingPortalDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Billing/Portal`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BillingPortalDTO>;
      })
    );
  }
  /**
   * Gets signed link to the Stripe portal
   * @param Authorization Bearer {token}
   * @return OK
   */
  Portal(Authorization: string): __Observable<BillingPortalDTO> {
    return this.PortalResponse(Authorization).pipe(
      __map(_r => _r.body as BillingPortalDTO)
    );
  }

  /**
   * Initialize a checkout session. Return ID for user to complete in browser.
   * @param Authorization Bearer {token}
   * @return OK
   */
  SubscribeResponse(Authorization: string): __Observable<__StrictHttpResponse<BillingSessionDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Billing/Subscribe`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BillingSessionDTO>;
      })
    );
  }
  /**
   * Initialize a checkout session. Return ID for user to complete in browser.
   * @param Authorization Bearer {token}
   * @return OK
   */
  Subscribe(Authorization: string): __Observable<BillingSessionDTO> {
    return this.SubscribeResponse(Authorization).pipe(
      __map(_r => _r.body as BillingSessionDTO)
    );
  }

  /**
   * Initialize a checkout session. Return ID for user to complete in browser.
   * @param params The `BillingService.CheckoutParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  CheckoutResponse(params: BillingService.CheckoutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Billing/Checkout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Initialize a checkout session. Return ID for user to complete in browser.
   * @param params The `BillingService.CheckoutParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  Checkout(params: BillingService.CheckoutParams): __Observable<null> {
    return this.CheckoutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module BillingService {

  /**
   * Parameters for Checkout
   */
  export interface CheckoutParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: BillingSessionDTO;
  }
}

export { BillingService }
