/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TempUser } from '../models/temp-user';
@Injectable({
  providedIn: 'root',
})
class TempUserService extends __BaseService {
  static readonly CreatePath = '/api/TempUser';
  static readonly GetByConfirmationCodePath = '/api/TempUser';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Create a new temp user record
   * @param body undefined
   */
  CreateResponse(body?: TempUser): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/TempUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Create a new temp user record
   * @param body undefined
   */
  Create(body?: TempUser): __Observable<null> {
    return this.CreateResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get temp user record by confirmation code
   * @param confirmationCode undefined
   * @return Success
   */
  GetByConfirmationCodeResponse(confirmationCode?: string): __Observable<__StrictHttpResponse<TempUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (confirmationCode != null) __params = __params.set('confirmationCode', confirmationCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TempUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TempUser>;
      })
    );
  }
  /**
   * Get temp user record by confirmation code
   * @param confirmationCode undefined
   * @return Success
   */
  GetByConfirmationCode(confirmationCode?: string): __Observable<TempUser> {
    return this.GetByConfirmationCodeResponse(confirmationCode).pipe(
      __map(_r => _r.body as TempUser)
    );
  }
}

module TempUserService {
}

export { TempUserService }
