/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DriverProfileSummaryDTO } from '../models/driver-profile-summary-dto';
import { DriverProfileDTO } from '../models/driver-profile-dto';
import { IonicLocationDTO } from '../models/ionic-location-dto';
import { GeoLocationDTO } from '../models/geo-location-dto';
@Injectable({
  providedIn: 'root',
})
class DriverProfileService extends __BaseService {
  static readonly GetPath = '/api/DriverProfile';
  static readonly PostPath = '/api/DriverProfile';
  static readonly GetDriverProfileByIdPath = '/api/DriverProfile/{id}';
  static readonly PutPath = '/api/DriverProfile/{id}';
  static readonly GetByUserIdPath = '/api/DriverProfile/ByUserId/{userId}';
  static readonly TrackGpsPath = '/api/DriverProfile/TrackGps';
  static readonly TrackGpsIonicPath = '/api/DriverProfile/TrackGpsIonic';
  static readonly TrackGpsV3Path = '/api/DriverProfile/v3/locations';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Summary list of all driver profile records.
   * @param Authorization Bearer {token}
   * @return OK
   */
  GetResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<DriverProfileSummaryDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DriverProfile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DriverProfileSummaryDTO>>;
      })
    );
  }
  /**
   * Summary list of all driver profile records.
   * @param Authorization Bearer {token}
   * @return OK
   */
  Get(Authorization: string): __Observable<Array<DriverProfileSummaryDTO>> {
    return this.GetResponse(Authorization).pipe(
      __map(_r => _r.body as Array<DriverProfileSummaryDTO>)
    );
  }

  /**
   * Creates a new user with the driver role and given profile.
   * @param params The `DriverProfileService.PostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Created
   */
  PostResponse(params: DriverProfileService.PostParams): __Observable<__StrictHttpResponse<DriverProfileDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DriverProfile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DriverProfileDTO>;
      })
    );
  }
  /**
   * Creates a new user with the driver role and given profile.
   * @param params The `DriverProfileService.PostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Created
   */
  Post(params: DriverProfileService.PostParams): __Observable<DriverProfileDTO> {
    return this.PostResponse(params).pipe(
      __map(_r => _r.body as DriverProfileDTO)
    );
  }

  /**
   * Get Profile By DriverProfileId
   * @param params The `DriverProfileService.GetDriverProfileByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return OK
   */
  GetDriverProfileByIdResponse(params: DriverProfileService.GetDriverProfileByIdParams): __Observable<__StrictHttpResponse<DriverProfileDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DriverProfile/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DriverProfileDTO>;
      })
    );
  }
  /**
   * Get Profile By DriverProfileId
   * @param params The `DriverProfileService.GetDriverProfileByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return OK
   */
  GetDriverProfileById(params: DriverProfileService.GetDriverProfileByIdParams): __Observable<DriverProfileDTO> {
    return this.GetDriverProfileByIdResponse(params).pipe(
      __map(_r => _r.body as DriverProfileDTO)
    );
  }

  /**
   * Update an existing DriverProfile
   * @param params The `DriverProfileService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  PutResponse(params: DriverProfileService.PutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/DriverProfile/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update an existing DriverProfile
   * @param params The `DriverProfileService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  Put(params: DriverProfileService.PutParams): __Observable<null> {
    return this.PutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Driver Profile by User Id
   * @param params The `DriverProfileService.GetByUserIdParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return OK
   */
  GetByUserIdResponse(params: DriverProfileService.GetByUserIdParams): __Observable<__StrictHttpResponse<DriverProfileDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DriverProfile/ByUserId/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DriverProfileDTO>;
      })
    );
  }
  /**
   * Get Driver Profile by User Id
   * @param params The `DriverProfileService.GetByUserIdParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return OK
   */
  GetByUserId(params: DriverProfileService.GetByUserIdParams): __Observable<DriverProfileDTO> {
    return this.GetByUserIdResponse(params).pipe(
      __map(_r => _r.body as DriverProfileDTO)
    );
  }

  /**
   * Receives GPS data for a driver. Pass in city/state if available also
   * @param params The `DriverProfileService.TrackGpsParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `state`:
   *
   * - `longitude`:
   *
   * - `latitude`:
   *
   * - `city`:
   */
  TrackGpsResponse(params: DriverProfileService.TrackGpsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.state != null) __params = __params.set('state', params.state.toString());
    if (params.longitude != null) __params = __params.set('longitude', params.longitude.toString());
    if (params.latitude != null) __params = __params.set('latitude', params.latitude.toString());
    if (params.city != null) __params = __params.set('city', params.city.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DriverProfile/TrackGps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Receives GPS data for a driver. Pass in city/state if available also
   * @param params The `DriverProfileService.TrackGpsParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `state`:
   *
   * - `longitude`:
   *
   * - `latitude`:
   *
   * - `city`:
   */
  TrackGps(params: DriverProfileService.TrackGpsParams): __Observable<null> {
    return this.TrackGpsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Receives GPS data for a driver (cordova http post)
   * @param params The `DriverProfileService.TrackGpsIonicParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  TrackGpsIonicResponse(params: DriverProfileService.TrackGpsIonicParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DriverProfile/TrackGpsIonic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Receives GPS data for a driver (cordova http post)
   * @param params The `DriverProfileService.TrackGpsIonicParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  TrackGpsIonic(params: DriverProfileService.TrackGpsIonicParams): __Observable<null> {
    return this.TrackGpsIonicResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Receives GPS data for a driver (latest iteration for mobile app tracking).
   * @param params The `DriverProfileService.TrackGpsV3Params` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  TrackGpsV3Response(params: DriverProfileService.TrackGpsV3Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DriverProfile/v3/locations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Receives GPS data for a driver (latest iteration for mobile app tracking).
   * @param params The `DriverProfileService.TrackGpsV3Params` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  TrackGpsV3(params: DriverProfileService.TrackGpsV3Params): __Observable<null> {
    return this.TrackGpsV3Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DriverProfileService {

  /**
   * Parameters for Post
   */
  export interface PostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: DriverProfileDTO;
  }

  /**
   * Parameters for GetDriverProfileById
   */
  export interface GetDriverProfileByIdParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for Put
   */
  export interface PutParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: DriverProfileDTO;
  }

  /**
   * Parameters for GetByUserId
   */
  export interface GetByUserIdParams {
    userId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for TrackGps
   */
  export interface TrackGpsParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    state?: string;
    longitude?: number;
    latitude?: number;
    city?: string;
  }

  /**
   * Parameters for TrackGpsIonic
   */
  export interface TrackGpsIonicParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: Array<IonicLocationDTO>;
  }

  /**
   * Parameters for TrackGpsV3
   */
  export interface TrackGpsV3Params {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: GeoLocationDTO;
  }
}

export { DriverProfileService }
