/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FactoringCompanyOptionsDTO } from '../models/factoring-company-options-dto';
import { FactoringRemitToOptionsDTO } from '../models/factoring-remit-to-options-dto';
@Injectable({
  providedIn: 'root',
})
class FactoringCompanyOptionsService extends __BaseService {
  static readonly GetPath = '/api/Factoring/CompanyOptions';
  static readonly UpdatePath = '/api/Factoring/CompanyOptions';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets FactoringCompany app settings values
   * @param Authorization Bearer {token}
   * @return Success
   */
  GetResponse(Authorization: string): __Observable<__StrictHttpResponse<FactoringCompanyOptionsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Factoring/CompanyOptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FactoringCompanyOptionsDTO>;
      })
    );
  }
  /**
   * Gets FactoringCompany app settings values
   * @param Authorization Bearer {token}
   * @return Success
   */
  Get(Authorization: string): __Observable<FactoringCompanyOptionsDTO> {
    return this.GetResponse(Authorization).pipe(
      __map(_r => _r.body as FactoringCompanyOptionsDTO)
    );
  }

  /**
   * Updates the settings table factoring remit to company values.
   * @param params The `FactoringCompanyOptionsService.UpdateParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateResponse(params: FactoringCompanyOptionsService.UpdateParams): __Observable<__StrictHttpResponse<FactoringRemitToOptionsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Factoring/CompanyOptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FactoringRemitToOptionsDTO>;
      })
    );
  }
  /**
   * Updates the settings table factoring remit to company values.
   * @param params The `FactoringCompanyOptionsService.UpdateParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Success
   */
  Update(params: FactoringCompanyOptionsService.UpdateParams): __Observable<FactoringRemitToOptionsDTO> {
    return this.UpdateResponse(params).pipe(
      __map(_r => _r.body as FactoringRemitToOptionsDTO)
    );
  }
}

module FactoringCompanyOptionsService {

  /**
   * Parameters for Update
   */
  export interface UpdateParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: FactoringRemitToOptionsDTO;
  }
}

export { FactoringCompanyOptionsService }
