/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserDTO } from '../models/user-dto';
import { ServiceResponseDTO } from '../models/service-response-dto';
import { IdNamePairDTO } from '../models/id-name-pair-dto';
@Injectable({
  providedIn: 'root',
})
class AllUserService extends __BaseService {
  static readonly GetByTenantIdPath = '/api/AllUser/{tenantId}';
  static readonly PostPath = '/api/AllUser/{tenantId}';
  static readonly GetPath = '/api/AllUser/{tenantId}/{userId}';
  static readonly PutPath = '/api/AllUser/{tenantId}/{userId}';
  static readonly EnableUserPath = '/api/AllUser/Enable/{tenantId}/{userId}';
  static readonly DisableUserPath = '/api/AllUser/Disable/{tenantId}/{userId}';
  static readonly ResetLockoutPath = '/api/AllUser/ResetLockout/{userId}';
  static readonly NamesPath = '/api/AllUser/Names/{tenantId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Summary list of users
   * @param params The `AllUserService.GetByTenantIdParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetByTenantIdResponse(params: AllUserService.GetByTenantIdParams): __Observable<__StrictHttpResponse<Array<UserDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AllUser/${encodeURIComponent(String(params.tenantId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserDTO>>;
      })
    );
  }
  /**
   * Summary list of users
   * @param params The `AllUserService.GetByTenantIdParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetByTenantId(params: AllUserService.GetByTenantIdParams): __Observable<Array<UserDTO>> {
    return this.GetByTenantIdResponse(params).pipe(
      __map(_r => _r.body as Array<UserDTO>)
    );
  }

  /**
   * Creates a new user or adds existing user to tenant.
   * @param params The `AllUserService.PostParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Created
   */
  PostResponse(params: AllUserService.PostParams): __Observable<__StrictHttpResponse<UserDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AllUser/${encodeURIComponent(String(params.tenantId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDTO>;
      })
    );
  }
  /**
   * Creates a new user or adds existing user to tenant.
   * @param params The `AllUserService.PostParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Created
   */
  Post(params: AllUserService.PostParams): __Observable<UserDTO> {
    return this.PostResponse(params).pipe(
      __map(_r => _r.body as UserDTO)
    );
  }

  /**
   * User detail
   * @param params The `AllUserService.GetParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetResponse(params: AllUserService.GetParams): __Observable<__StrictHttpResponse<UserDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AllUser/${encodeURIComponent(String(params.tenantId))}/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDTO>;
      })
    );
  }
  /**
   * User detail
   * @param params The `AllUserService.GetParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  Get(params: AllUserService.GetParams): __Observable<UserDTO> {
    return this.GetResponse(params).pipe(
      __map(_r => _r.body as UserDTO)
    );
  }

  /**
   * Update an existing user.
   * @param params The `AllUserService.PutParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Check the response object statusCode.
   * --200 indicates user updated and no further work is required
   * --202 Indicates that the current user's email has changed
   * ----A verification code is sent to their new email and user has been signed out.
   * ----User will need to log in with the new email address and enter the confirmation code (user/verifyEmail)
   * ----FYI: An unverified user cannot use the /token/ForgotPassword. Its important for them to verify.
   */
  PutResponse(params: AllUserService.PutParams): __Observable<__StrictHttpResponse<ServiceResponseDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/AllUser/${encodeURIComponent(String(params.tenantId))}/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServiceResponseDTO>;
      })
    );
  }
  /**
   * Update an existing user.
   * @param params The `AllUserService.PutParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Check the response object statusCode.
   * --200 indicates user updated and no further work is required
   * --202 Indicates that the current user's email has changed
   * ----A verification code is sent to their new email and user has been signed out.
   * ----User will need to log in with the new email address and enter the confirmation code (user/verifyEmail)
   * ----FYI: An unverified user cannot use the /token/ForgotPassword. Its important for them to verify.
   */
  Put(params: AllUserService.PutParams): __Observable<ServiceResponseDTO> {
    return this.PutResponse(params).pipe(
      __map(_r => _r.body as ServiceResponseDTO)
    );
  }

  /**
   * Enables a user for the tenant
   * @param params The `AllUserService.EnableUserParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   */
  EnableUserResponse(params: AllUserService.EnableUserParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AllUser/Enable/${encodeURIComponent(String(params.tenantId))}/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Enables a user for the tenant
   * @param params The `AllUserService.EnableUserParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   */
  EnableUser(params: AllUserService.EnableUserParams): __Observable<null> {
    return this.EnableUserResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Disable a user for the tenant
   * @param params The `AllUserService.DisableUserParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   */
  DisableUserResponse(params: AllUserService.DisableUserParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AllUser/Disable/${encodeURIComponent(String(params.tenantId))}/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Disable a user for the tenant
   * @param params The `AllUserService.DisableUserParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   */
  DisableUser(params: AllUserService.DisableUserParams): __Observable<null> {
    return this.DisableUserResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Resets the lockout flag and FailedLogins.
   * @param params The `AllUserService.ResetLockoutParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `Authorization`: Bearer {token}
   */
  ResetLockoutResponse(params: AllUserService.ResetLockoutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AllUser/ResetLockout/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Resets the lockout flag and FailedLogins.
   * @param params The `AllUserService.ResetLockoutParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `Authorization`: Bearer {token}
   */
  ResetLockout(params: AllUserService.ResetLockoutParams): __Observable<null> {
    return this.ResetLockoutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Gets user Ids and Names for use in drop-downs
   * @param params The `AllUserService.NamesParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `IncludeRoles`:
   *
   * - `IncludeDisabled`:
   *
   * - `ExcludeRoles`:
   *
   * - `ExcludeDriverOnly`:
   *
   * @return Success - Returns user Id and Name
   */
  NamesResponse(params: AllUserService.NamesParams): __Observable<__StrictHttpResponse<Array<IdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.IncludeRoles || []).forEach(val => {if (val != null) __params = __params.append('IncludeRoles', val.toString())});
    if (params.IncludeDisabled != null) __params = __params.set('IncludeDisabled', params.IncludeDisabled.toString());
    (params.ExcludeRoles || []).forEach(val => {if (val != null) __params = __params.append('ExcludeRoles', val.toString())});
    if (params.ExcludeDriverOnly != null) __params = __params.set('ExcludeDriverOnly', params.ExcludeDriverOnly.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AllUser/Names/${encodeURIComponent(String(params.tenantId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdNamePairDTO>>;
      })
    );
  }
  /**
   * Gets user Ids and Names for use in drop-downs
   * @param params The `AllUserService.NamesParams` containing the following parameters:
   *
   * - `tenantId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `IncludeRoles`:
   *
   * - `IncludeDisabled`:
   *
   * - `ExcludeRoles`:
   *
   * - `ExcludeDriverOnly`:
   *
   * @return Success - Returns user Id and Name
   */
  Names(params: AllUserService.NamesParams): __Observable<Array<IdNamePairDTO>> {
    return this.NamesResponse(params).pipe(
      __map(_r => _r.body as Array<IdNamePairDTO>)
    );
  }
}

module AllUserService {

  /**
   * Parameters for GetByTenantId
   */
  export interface GetByTenantIdParams {
    tenantId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for Post
   */
  export interface PostParams {
    tenantId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: UserDTO;
  }

  /**
   * Parameters for Get
   */
  export interface GetParams {
    userId: number;
    tenantId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for Put
   */
  export interface PutParams {
    userId: number;
    tenantId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: UserDTO;
  }

  /**
   * Parameters for EnableUser
   */
  export interface EnableUserParams {
    userId: number;
    tenantId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for DisableUser
   */
  export interface DisableUserParams {
    userId: number;
    tenantId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ResetLockout
   */
  export interface ResetLockoutParams {
    userId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for Names
   */
  export interface NamesParams {
    tenantId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    IncludeRoles?: Array<string>;
    IncludeDisabled?: boolean;
    ExcludeRoles?: Array<string>;
    ExcludeDriverOnly?: boolean;
  }
}

export { AllUserService }
