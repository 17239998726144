/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ContactAddressDTO } from '../models/contact-address-dto';
@Injectable({
  providedIn: 'root',
})
class LogisticsTenantAccountingService extends __BaseService {
  static readonly GetAccountingContactAddressByIdPath = '/api/LogisticsTenantAccounting/{id}';
  static readonly UpdateAccountingContactAddressByIdPath = '/api/LogisticsTenantAccounting';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Accounting Contact Address By Id
   * @param params The `LogisticsTenantAccountingService.GetAccountingContactAddressByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetAccountingContactAddressByIdResponse(params: LogisticsTenantAccountingService.GetAccountingContactAddressByIdParams): __Observable<__StrictHttpResponse<ContactAddressDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/LogisticsTenantAccounting/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ContactAddressDTO>;
      })
    );
  }
  /**
   * Get Accounting Contact Address By Id
   * @param params The `LogisticsTenantAccountingService.GetAccountingContactAddressByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetAccountingContactAddressById(params: LogisticsTenantAccountingService.GetAccountingContactAddressByIdParams): __Observable<ContactAddressDTO> {
    return this.GetAccountingContactAddressByIdResponse(params).pipe(
      __map(_r => _r.body as ContactAddressDTO)
    );
  }

  /**
   * Updates Accounting Contact Address
   * @param params The `LogisticsTenantAccountingService.UpdateAccountingContactAddressByIdParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateAccountingContactAddressByIdResponse(params: LogisticsTenantAccountingService.UpdateAccountingContactAddressByIdParams): __Observable<__StrictHttpResponse<ContactAddressDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/LogisticsTenantAccounting`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ContactAddressDTO>;
      })
    );
  }
  /**
   * Updates Accounting Contact Address
   * @param params The `LogisticsTenantAccountingService.UpdateAccountingContactAddressByIdParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateAccountingContactAddressById(params: LogisticsTenantAccountingService.UpdateAccountingContactAddressByIdParams): __Observable<ContactAddressDTO> {
    return this.UpdateAccountingContactAddressByIdResponse(params).pipe(
      __map(_r => _r.body as ContactAddressDTO)
    );
  }
}

module LogisticsTenantAccountingService {

  /**
   * Parameters for GetAccountingContactAddressById
   */
  export interface GetAccountingContactAddressByIdParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for UpdateAccountingContactAddressById
   */
  export interface UpdateAccountingContactAddressByIdParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: ContactAddressDTO;
  }
}

export { LogisticsTenantAccountingService }
