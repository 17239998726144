/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RolePermissionsDTO } from '../models/role-permissions-dto';
import { TokenResponseDTO } from '../models/token-response-dto';
import { ImpersonationRequestDTO } from '../models/impersonation-request-dto';
import { TokenDTO } from '../models/token-dto';
@Injectable({
  providedIn: 'root',
})
class SuperAdminService extends __BaseService {
  static readonly VerifyEmailPath = '/api/SuperAdmin/User/VerifyEmail';
  static readonly UserResetLockoutPath = '/api/SuperAdmin/User/ResetLockout';
  static readonly AllRolesPath = '/api/SuperAdmin/Role/All';
  static readonly UserDeletePath = '/api/SuperAdmin/User/Delete';
  static readonly ImpersonatePath = '/api/SuperAdmin/User/Impersonate';
  static readonly UpdateTenantExemptStatusPath = '/api/SuperAdmin/Tenant/Exempt';
  static readonly GetIncompleteTempUserRegistrationsPath = '/api/SuperAdmin/TempUser/IncompleteRegistrations';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Set a user's email status to confirmed
   * @param params The `SuperAdminService.VerifyEmailParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `email`:
   */
  VerifyEmailResponse(params: SuperAdminService.VerifyEmailParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/SuperAdmin/User/VerifyEmail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Set a user's email status to confirmed
   * @param params The `SuperAdminService.VerifyEmailParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `email`:
   */
  VerifyEmail(params: SuperAdminService.VerifyEmailParams): __Observable<null> {
    return this.VerifyEmailResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Unlocks a user
   * @param params The `SuperAdminService.UserResetLockoutParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `email`:
   */
  UserResetLockoutResponse(params: SuperAdminService.UserResetLockoutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/SuperAdmin/User/ResetLockout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Unlocks a user
   * @param params The `SuperAdminService.UserResetLockoutParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `email`:
   */
  UserResetLockout(params: SuperAdminService.UserResetLockoutParams): __Observable<null> {
    return this.UserResetLockoutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns all roles with their IDs and permissions
   * This is a special method that queries DB directly. Not intended for app, only troubleshooting.
   * @param Authorization Bearer {token}
   * @return Success
   */
  AllRolesResponse(Authorization: string): __Observable<__StrictHttpResponse<RolePermissionsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/SuperAdmin/Role/All`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RolePermissionsDTO>;
      })
    );
  }
  /**
   * Returns all roles with their IDs and permissions
   * This is a special method that queries DB directly. Not intended for app, only troubleshooting.
   * @param Authorization Bearer {token}
   * @return Success
   */
  AllRoles(Authorization: string): __Observable<RolePermissionsDTO> {
    return this.AllRolesResponse(Authorization).pipe(
      __map(_r => _r.body as RolePermissionsDTO)
    );
  }

  /**
   * Deletes a user from database and cognito.
   * This is a testing operation that is not intended for production.
   * @param params The `SuperAdminService.UserDeleteParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `email`:
   */
  UserDeleteResponse(params: SuperAdminService.UserDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/SuperAdmin/User/Delete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes a user from database and cognito.
   * This is a testing operation that is not intended for production.
   * @param params The `SuperAdminService.UserDeleteParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `email`:
   */
  UserDelete(params: SuperAdminService.UserDeleteParams): __Observable<null> {
    return this.UserDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Generates a special token for the super admin to log into another tenant.
   * Will copy the requested user's roles
   * @param params The `SuperAdminService.ImpersonateParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return The JWT access token
   */
  ImpersonateResponse(params: SuperAdminService.ImpersonateParams): __Observable<__StrictHttpResponse<TokenResponseDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/SuperAdmin/User/Impersonate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TokenResponseDTO>;
      })
    );
  }
  /**
   * Generates a special token for the super admin to log into another tenant.
   * Will copy the requested user's roles
   * @param params The `SuperAdminService.ImpersonateParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return The JWT access token
   */
  Impersonate(params: SuperAdminService.ImpersonateParams): __Observable<TokenResponseDTO> {
    return this.ImpersonateResponse(params).pipe(
      __map(_r => _r.body as TokenResponseDTO)
    );
  }

  /**
   * Allows super admin to make a tenant exempt from subscription billing.
   * @param params The `SuperAdminService.UpdateTenantExemptStatusParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `tenantId`:
   *
   * - `isExempt`:
   *
   * @return Success
   */
  UpdateTenantExemptStatusResponse(params: SuperAdminService.UpdateTenantExemptStatusParams): __Observable<__StrictHttpResponse<TokenDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.isExempt != null) __params = __params.set('isExempt', params.isExempt.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/SuperAdmin/Tenant/Exempt`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TokenDTO>;
      })
    );
  }
  /**
   * Allows super admin to make a tenant exempt from subscription billing.
   * @param params The `SuperAdminService.UpdateTenantExemptStatusParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `tenantId`:
   *
   * - `isExempt`:
   *
   * @return Success
   */
  UpdateTenantExemptStatus(params: SuperAdminService.UpdateTenantExemptStatusParams): __Observable<TokenDTO> {
    return this.UpdateTenantExemptStatusResponse(params).pipe(
      __map(_r => _r.body as TokenDTO)
    );
  }

  /**
   * Allows super admin to get a list of temp user registrations in a given date range.
   * @param params The `SuperAdminService.GetIncompleteTempUserRegistrationsParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return A CSV file
   */
  GetIncompleteTempUserRegistrationsResponse(params: SuperAdminService.GetIncompleteTempUserRegistrationsParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/SuperAdmin/TempUser/IncompleteRegistrations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Allows super admin to get a list of temp user registrations in a given date range.
   * @param params The `SuperAdminService.GetIncompleteTempUserRegistrationsParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return A CSV file
   */
  GetIncompleteTempUserRegistrations(params: SuperAdminService.GetIncompleteTempUserRegistrationsParams): __Observable<Blob> {
    return this.GetIncompleteTempUserRegistrationsResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module SuperAdminService {

  /**
   * Parameters for VerifyEmail
   */
  export interface VerifyEmailParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    email?: string;
  }

  /**
   * Parameters for UserResetLockout
   */
  export interface UserResetLockoutParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    email?: string;
  }

  /**
   * Parameters for UserDelete
   */
  export interface UserDeleteParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    email?: string;
  }

  /**
   * Parameters for Impersonate
   */
  export interface ImpersonateParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: ImpersonationRequestDTO;
  }

  /**
   * Parameters for UpdateTenantExemptStatus
   */
  export interface UpdateTenantExemptStatusParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    tenantId?: number;
    isExempt?: boolean;
  }

  /**
   * Parameters for GetIncompleteTempUserRegistrations
   */
  export interface GetIncompleteTempUserRegistrationsParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    startDate?: string;
    endDate?: string;
  }
}

export { SuperAdminService }
