import { OnInit, Injector, Directive } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, ValidationErrors } from '@angular/forms';
import { ApiValidators } from '../../utils/api-validators';
import { ValidatorsService } from '../core/validators-service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ClientTokenService } from '../../services/client-token-service';
import { HandleErrorBase } from './HandleErrorBase';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class FormBase<TModel> extends HandleErrorBase implements OnInit {
  protected clientToken: ClientTokenService;
  protected router: Router;
  protected fb: FormBuilder;

  public model: TModel;
  apiProblemsFound = false;

  showProgressOverlay: boolean = false;

  prefix: string = '';
  formGroup: FormGroup;
  formErrors: any = {};
  validationMessages = {};

  constructor(protected injector: Injector) {
    super(injector);

    this.clientToken = this.injector.get(ClientTokenService);
    this.router = this.injector.get(Router);
    this.fb = this.injector.get(FormBuilder);

    this.model = {} as TModel;
    this.apiProblemsFound = false;
  }

  static toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  abstract formValid(): boolean;

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.formGroup = this.fb.group({});

    this.formGroup.valueChanges.subscribe((data) => this.onEditValueChanged(data, this.formGroup));
  }

  onEditValueChanged(data: any, form: any) {
    // tslint:disable-next-line:forin
    for (const field in form.controls) {
      const control = form.get(field);
      if (this.formErrors[field] !== undefined && this.formErrors[field] !== '') {
        // clear previous error message (if any)
        if (!this.apiProblemsFound && control.valid) {
          this.formErrors[field] = '';
        }
      }

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        if (messages) {
          // tslint:disable-next-line:forin
          for (const key in control.errors) {
            if (this.formErrors[field] === undefined) {
              this.formErrors[field] = messages[key] + ' ';
            } else {
              if (!this.formErrors[field].includes(messages[key])) {
                this.formErrors[field] += messages[key] + ' ';
              }
            }
          }
        }
      }
    }
  }

  initForm(modelValidators: any, nonModelFields?: Array<string>, groupValidators?: any) {
    this.validationMessages = ApiValidators.getValidationMessagesForFormGroup(modelValidators);

    this.formErrors = {};
    this.formGroup = this.fb.group({});

    if (nonModelFields) {
      for (const key of Object.keys(nonModelFields)) {
        this.addFormControl(nonModelFields[key], modelValidators);
        if (ApiValidators.nonDTOValidationMessages[nonModelFields[key]]) {
          for (let validator of Object.keys(ApiValidators.nonDTOValidationMessages[nonModelFields[key]])) {
            if (validator === 'requiredTrue') {
              validator = 'required';
            }
            const validatorMessage = ApiValidators.nonDTOValidationMessages[nonModelFields[key]][validator];
            if (!this.validationMessages[nonModelFields[key]]) {
              this.validationMessages[nonModelFields[key]] = new Object();
            }
            this.validationMessages[nonModelFields[key]][validator.toLowerCase()] = validatorMessage;
          }
        }
      }
    }

    this.addFormControls(modelValidators);

    if (groupValidators) {
      this.formGroup.setValidators(groupValidators);
    }

    this.formGroup.valueChanges.subscribe((data) => this.onEditValueChanged(data, this.formGroup));
  }

  addFormControl(field: string, modelValidators: any) {
    const fieldName = this.prefix === '' ? field : this.prefix + '-' + field;
    this.formGroup.addControl(
      fieldName,
      new FormControl(this.model[field], ApiValidators.getValidatorsForFormField(fieldName, modelValidators))
    );
  }

  addFormControls(modelValidators: any) {
    for (const fieldName of Object.keys(modelValidators)) {
      this.addFormControl(fieldName, modelValidators);
    }
  }

  /**
   * Adds special handling for form errors
   * TODO: Rename to HandleFormError
   */
  protected handleError = (error: HttpErrorResponse): Observable<never> => {
    switch (error.status) {
      case 400:
        this.logging.logBadRequest(error);
        this.handleBadRequest(error.error);
        break;
      default:
        this.handleBasicError(error);
        break;
    }

    this.showProgressOverlay = false;

    return throwError(error);
  };

  // Used as a call back so must implement w/ fat arrow
  protected handleBadRequest = (error: any) => {
    this.showProgressOverlay = false;
    this.apiProblemsFound = true;
    const problems = typeof error === 'string' ? JSON.parse(error) : error;
    for (const field in this.formGroup.controls) {
      if (this.formErrors[field] !== undefined && this.formErrors[field] !== '') {
        // clear previous error message (if any)
        this.formErrors[field] = '';
      }
    }
    if (problems) {
      // tslint:disable-next-line:forin
      for (const key in problems) {
        const messages = problems[key].join(', ');
        let keyField = '';

        if (key.indexOf('.') > -1) {
          // Convert parts to camel case. Replace periods w/ dashes.
          const parts = key.split('.');
          for (let i = 0; i < parts.length; i++) {
            keyField += parts[i].substr(0, 1).toLowerCase() + parts[i].substr(1) + '-';
          }
          // Trim off extra dash
          keyField = keyField.substr(0, keyField.length - 1);
        } else {
          keyField = key.substr(0, 1).toLowerCase() + key.substr(1);
        }

        if (!this.formGroup.contains(keyField)) {
          // No visible component, so show growler
          this._NS.error('Validation Error', `${key}: ${messages}`);
        } else {
          this.formGroup.controls[keyField].markAsTouched();
          this.formErrors[keyField] = messages;
        }

        // this.logging.logTrace(`Growl Detail: ${key} - ${messages}`);
        // this._NS.error('Validation Error', messages);
        /*
        if (this.formGroup.get(keyField) || this.formErrors.hasOwnProperty(keyField)) {
        } else {
          this.formErrors[keyField] = messages;
          this.logging.logTrace(`Growl Detail: ${key} - ${messages}`);
          this._NS.error('Validation Error', messages);
        }
        */
      }
    }
  };

  /**
   * Used for debugging. Displays any validation errors on the form
   * Add a button to call this to check state.
   * source: https://stackoverflow.com/questions/40680321/get-all-validation-errors-from-angular-2-formgroup
   */
  printFormValidationErrors() {
    console.log('this.formGroup.valid:', this.formGroup.valid);
    console.log('this.formErrors:', this.formErrors);
    console.log('printing this.formGroup.controls errors:');
    Object.keys(this.formGroup.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.formGroup.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
}

export abstract class DtoFormBase<TModel> extends FormBase<TModel> implements OnInit {
  protected validatorsService: ValidatorsService;

  constructor(protected injector: Injector) {
    super(injector);
    this.validatorsService = this.injector.get(ValidatorsService);
  }

  initFormFor(dtoName: string, nonModelFields?: string[], groupValidators?: any) {
    const validators = this.validatorsService.getByName(dtoName);
    if (!validators) {
      console.error(`Unable to locate validators for ${dtoName}`);
    }
    this.initForm(validators, nonModelFields, groupValidators);
  }

  formValid(): boolean {
    return ApiValidators.validateForm(this.formGroup, this.validationMessages, this.formErrors);
  }
}
