/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PagedCarrierSummaryDTO } from '../models/paged-carrier-summary-dto';
import { CarrierDTO } from '../models/carrier-dto';
import { Operation } from '../models/operation';
import { AttachmentDTO } from '../models/attachment-dto';
@Injectable({
  providedIn: 'root',
})
class CarrierService extends __BaseService {
  static readonly GetCarriersPath = '/api/Carrier';
  static readonly CreateCarrierPath = '/api/Carrier';
  static readonly GetCarrierPath = '/api/Carrier/{id}';
  static readonly PartiallyUpdateCarrierPath = '/api/Carrier/{id}';
  static readonly UpdateCarrierPath = '/api/Carrier/{id}';
  static readonly UploadCarrierPacketPath = '/api/Carrier/{id}/carrier-packet';
  static readonly UpdateCarrierPacketPath = '/api/Carrier/{id}/carrier-packet';
  static readonly DownloadCarrierPacketPath = '/api/Carrier/{id}/carrier-packet/{attachmentId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets all carriers for user
   * @param params The `CarrierService.GetCarriersParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `SortByAscending`:
   *
   * - `SortBy`:
   *
   * - `SearchTerm`:
   *
   * - `PageSize`:
   *
   * - `PageIndex`:
   *
   * @return Success
   */
  GetCarriersResponse(params: CarrierService.GetCarriersParams): __Observable<__StrictHttpResponse<PagedCarrierSummaryDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.SortByAscending != null) __params = __params.set('SortByAscending', params.SortByAscending.toString());
    if (params.SortBy != null) __params = __params.set('SortBy', params.SortBy.toString());
    if (params.SearchTerm != null) __params = __params.set('SearchTerm', params.SearchTerm.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.PageIndex != null) __params = __params.set('PageIndex', params.PageIndex.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Carrier`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedCarrierSummaryDTO>;
      })
    );
  }
  /**
   * Gets all carriers for user
   * @param params The `CarrierService.GetCarriersParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `SortByAscending`:
   *
   * - `SortBy`:
   *
   * - `SearchTerm`:
   *
   * - `PageSize`:
   *
   * - `PageIndex`:
   *
   * @return Success
   */
  GetCarriers(params: CarrierService.GetCarriersParams): __Observable<PagedCarrierSummaryDTO> {
    return this.GetCarriersResponse(params).pipe(
      __map(_r => _r.body as PagedCarrierSummaryDTO)
    );
  }

  /**
   * Create new carrier
   * @param params The `CarrierService.CreateCarrierParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Success
   */
  CreateCarrierResponse(params: CarrierService.CreateCarrierParams): __Observable<__StrictHttpResponse<CarrierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Carrier`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CarrierDTO>;
      })
    );
  }
  /**
   * Create new carrier
   * @param params The `CarrierService.CreateCarrierParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Success
   */
  CreateCarrier(params: CarrierService.CreateCarrierParams): __Observable<CarrierDTO> {
    return this.CreateCarrierResponse(params).pipe(
      __map(_r => _r.body as CarrierDTO)
    );
  }

  /**
   * Gets carrier by Id
   * @param params The `CarrierService.GetCarrierParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetCarrierResponse(params: CarrierService.GetCarrierParams): __Observable<__StrictHttpResponse<CarrierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Carrier/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CarrierDTO>;
      })
    );
  }
  /**
   * Gets carrier by Id
   * @param params The `CarrierService.GetCarrierParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetCarrier(params: CarrierService.GetCarrierParams): __Observable<CarrierDTO> {
    return this.GetCarrierResponse(params).pipe(
      __map(_r => _r.body as CarrierDTO)
    );
  }

  /**
   * Partially update carrier
   * @param params The `CarrierService.PartiallyUpdateCarrierParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  PartiallyUpdateCarrierResponse(params: CarrierService.PartiallyUpdateCarrierParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/Carrier/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Partially update carrier
   * @param params The `CarrierService.PartiallyUpdateCarrierParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  PartiallyUpdateCarrier(params: CarrierService.PartiallyUpdateCarrierParams): __Observable<null> {
    return this.PartiallyUpdateCarrierResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update carrier
   * @param params The `CarrierService.UpdateCarrierParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  UpdateCarrierResponse(params: CarrierService.UpdateCarrierParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Carrier/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update carrier
   * @param params The `CarrierService.UpdateCarrierParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  UpdateCarrier(params: CarrierService.UpdateCarrierParams): __Observable<null> {
    return this.UpdateCarrierResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Upload carrier attachment
   * @param params The `CarrierService.UploadCarrierPacketParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `file`:
   *
   * @return The created object
   */
  UploadCarrierPacketResponse(params: CarrierService.UploadCarrierPacketParams): __Observable<__StrictHttpResponse<AttachmentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Carrier/${encodeURIComponent(String(params.id))}/carrier-packet`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AttachmentDTO>;
      })
    );
  }
  /**
   * Upload carrier attachment
   * @param params The `CarrierService.UploadCarrierPacketParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `file`:
   *
   * @return The created object
   */
  UploadCarrierPacket(params: CarrierService.UploadCarrierPacketParams): __Observable<AttachmentDTO> {
    return this.UploadCarrierPacketResponse(params).pipe(
      __map(_r => _r.body as AttachmentDTO)
    );
  }

  /**
   * Update existing carrier attachment
   * @param params The `CarrierService.UpdateCarrierPacketParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `file`:
   */
  UpdateCarrierPacketResponse(params: CarrierService.UpdateCarrierPacketParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Carrier/${encodeURIComponent(String(params.id))}/carrier-packet`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update existing carrier attachment
   * @param params The `CarrierService.UpdateCarrierPacketParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `file`:
   */
  UpdateCarrierPacket(params: CarrierService.UpdateCarrierPacketParams): __Observable<null> {
    return this.UpdateCarrierPacketResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Download carrier packet for carrier
   * @param params The `CarrierService.DownloadCarrierPacketParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `attachmentId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  DownloadCarrierPacketResponse(params: CarrierService.DownloadCarrierPacketParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Carrier/${encodeURIComponent(String(params.id))}/carrier-packet/${encodeURIComponent(String(params.attachmentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Download carrier packet for carrier
   * @param params The `CarrierService.DownloadCarrierPacketParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `attachmentId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  DownloadCarrierPacket(params: CarrierService.DownloadCarrierPacketParams): __Observable<Blob> {
    return this.DownloadCarrierPacketResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module CarrierService {

  /**
   * Parameters for GetCarriers
   */
  export interface GetCarriersParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    SortByAscending?: boolean;
    SortBy?: string;
    SearchTerm?: string;
    PageSize?: number;
    PageIndex?: number;
  }

  /**
   * Parameters for CreateCarrier
   */
  export interface CreateCarrierParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: CarrierDTO;
  }

  /**
   * Parameters for GetCarrier
   */
  export interface GetCarrierParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for PartiallyUpdateCarrier
   */
  export interface PartiallyUpdateCarrierParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: Array<Operation>;
  }

  /**
   * Parameters for UpdateCarrier
   */
  export interface UpdateCarrierParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: CarrierDTO;
  }

  /**
   * Parameters for UploadCarrierPacket
   */
  export interface UploadCarrierPacketParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    file?: Blob;
  }

  /**
   * Parameters for UpdateCarrierPacket
   */
  export interface UpdateCarrierPacketParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    file?: Blob;
  }

  /**
   * Parameters for DownloadCarrierPacket
   */
  export interface DownloadCarrierPacketParams {
    id: number;
    attachmentId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }
}

export { CarrierService }
