/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DebtorDTO } from '../models/debtor-dto';
import { MasterDebtor } from '../models/master-debtor';
@Injectable({
  providedIn: 'root',
})
class MasterDebtorService extends __BaseService {
  static readonly GetPath = '/api/Debtor/Master';
  static readonly GetByIdPath = '/api/Debtor/Master/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Searches Master Debtor list using specified search term
   * @param params The `MasterDebtorService.GetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `searchTerm`:
   *
   * @return Success
   */
  GetResponse(params: MasterDebtorService.GetParams): __Observable<__StrictHttpResponse<Array<DebtorDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.searchTerm != null) __params = __params.set('searchTerm', params.searchTerm.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Debtor/Master`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DebtorDTO>>;
      })
    );
  }
  /**
   * Searches Master Debtor list using specified search term
   * @param params The `MasterDebtorService.GetParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `searchTerm`:
   *
   * @return Success
   */
  Get(params: MasterDebtorService.GetParams): __Observable<Array<DebtorDTO>> {
    return this.GetResponse(params).pipe(
      __map(_r => _r.body as Array<DebtorDTO>)
    );
  }

  /**
   * Gets master debtor by ID
   * @param params The `MasterDebtorService.GetByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetByIdResponse(params: MasterDebtorService.GetByIdParams): __Observable<__StrictHttpResponse<MasterDebtor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Debtor/Master/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MasterDebtor>;
      })
    );
  }
  /**
   * Gets master debtor by ID
   * @param params The `MasterDebtorService.GetByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetById(params: MasterDebtorService.GetByIdParams): __Observable<MasterDebtor> {
    return this.GetByIdResponse(params).pipe(
      __map(_r => _r.body as MasterDebtor)
    );
  }
}

module MasterDebtorService {

  /**
   * Parameters for Get
   */
  export interface GetParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    searchTerm?: string;
  }

  /**
   * Parameters for GetById
   */
  export interface GetByIdParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }
}

export { MasterDebtorService }
