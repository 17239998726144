/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserDTO } from '../models/user-dto';
import { ServiceResponseDTO } from '../models/service-response-dto';
import { IdNamePairDTO } from '../models/id-name-pair-dto';
import { VerifyEmailDTO } from '../models/verify-email-dto';
import { TenantUserAttachmentDTO } from '../models/tenant-user-attachment-dto';
import { SendFileDTO } from '../models/send-file-dto';
@Injectable({
  providedIn: 'root',
})
class UserService extends __BaseService {
  static readonly GetPath = '/api/User';
  static readonly PostPath = '/api/User';
  static readonly GetUserByIdPath = '/api/User/{id}';
  static readonly PutPath = '/api/User/{id}';
  static readonly NamesPath = '/api/User/Names';
  static readonly NamesWithTenantUserIdPath = '/api/User/TenantUserNames';
  static readonly MePath = '/api/User/Me';
  static readonly EnableUserPath = '/api/User/{id}/Enable';
  static readonly DisableUserPath = '/api/User/{id}/Disable';
  static readonly VerifyEmailPath = '/api/User/VerifyEmail';
  static readonly ConfirmVerifyEmailPath = '/api/User/ConfirmVerifyEmail';
  static readonly ResetLockoutPath = '/api/User/ResetLockout';
  static readonly GetAttachmentsPath = '/api/User/{userId}/attachments';
  static readonly UploadAttachmentPath = '/api/User/{userId}/attachments';
  static readonly DownloadAttachmentPath = '/api/User/attachments/{id}';
  static readonly DeleteAttachmentPath = '/api/User/attachments/{id}';
  static readonly SendAttachmentPath = '/api/User/attachments/send';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Summary list of users
   * @param Authorization Bearer {token}
   * @return Success
   */
  GetResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<UserDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserDTO>>;
      })
    );
  }
  /**
   * Summary list of users
   * @param Authorization Bearer {token}
   * @return Success
   */
  Get(Authorization: string): __Observable<Array<UserDTO>> {
    return this.GetResponse(Authorization).pipe(
      __map(_r => _r.body as Array<UserDTO>)
    );
  }

  /**
   * Creates a new user or adds existing user to tenant.
   * @param params The `UserService.PostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Created
   */
  PostResponse(params: UserService.PostParams): __Observable<__StrictHttpResponse<UserDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDTO>;
      })
    );
  }
  /**
   * Creates a new user or adds existing user to tenant.
   * @param params The `UserService.PostParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Created
   */
  Post(params: UserService.PostParams): __Observable<UserDTO> {
    return this.PostResponse(params).pipe(
      __map(_r => _r.body as UserDTO)
    );
  }

  /**
   * User detail
   * @param params The `UserService.GetUserByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetUserByIdResponse(params: UserService.GetUserByIdParams): __Observable<__StrictHttpResponse<UserDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDTO>;
      })
    );
  }
  /**
   * User detail
   * @param params The `UserService.GetUserByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetUserById(params: UserService.GetUserByIdParams): __Observable<UserDTO> {
    return this.GetUserByIdResponse(params).pipe(
      __map(_r => _r.body as UserDTO)
    );
  }

  /**
   * Update an existing user.
   * @param params The `UserService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Check the response object statusCode.
   * --200 indicates user updated and no further work is required
   * --202 Indicates that the current user's email has changed
   * ----A verification code is sent to their new email and user has been signed out.
   * ----User will need to log in with the new email address and enter the confirmation code (user/verifyEmail)
   * ----FYI: An unverified user cannot use the /token/ForgotPassword. Its important for them to verify.
   */
  PutResponse(params: UserService.PutParams): __Observable<__StrictHttpResponse<ServiceResponseDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/User/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServiceResponseDTO>;
      })
    );
  }
  /**
   * Update an existing user.
   * @param params The `UserService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return Check the response object statusCode.
   * --200 indicates user updated and no further work is required
   * --202 Indicates that the current user's email has changed
   * ----A verification code is sent to their new email and user has been signed out.
   * ----User will need to log in with the new email address and enter the confirmation code (user/verifyEmail)
   * ----FYI: An unverified user cannot use the /token/ForgotPassword. Its important for them to verify.
   */
  Put(params: UserService.PutParams): __Observable<ServiceResponseDTO> {
    return this.PutResponse(params).pipe(
      __map(_r => _r.body as ServiceResponseDTO)
    );
  }

  /**
   * Gets user Ids and Names for use in drop-downs
   * @param params The `UserService.NamesParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `IncludeRoles`:
   *
   * - `IncludeDisabled`:
   *
   * - `ExcludeRoles`:
   *
   * - `ExcludeDriverOnly`:
   *
   * @return Success - Returns user Id and Name
   */
  NamesResponse(params: UserService.NamesParams): __Observable<__StrictHttpResponse<Array<IdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.IncludeRoles || []).forEach(val => {if (val != null) __params = __params.append('IncludeRoles', val.toString())});
    if (params.IncludeDisabled != null) __params = __params.set('IncludeDisabled', params.IncludeDisabled.toString());
    (params.ExcludeRoles || []).forEach(val => {if (val != null) __params = __params.append('ExcludeRoles', val.toString())});
    if (params.ExcludeDriverOnly != null) __params = __params.set('ExcludeDriverOnly', params.ExcludeDriverOnly.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/Names`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdNamePairDTO>>;
      })
    );
  }
  /**
   * Gets user Ids and Names for use in drop-downs
   * @param params The `UserService.NamesParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `IncludeRoles`:
   *
   * - `IncludeDisabled`:
   *
   * - `ExcludeRoles`:
   *
   * - `ExcludeDriverOnly`:
   *
   * @return Success - Returns user Id and Name
   */
  Names(params: UserService.NamesParams): __Observable<Array<IdNamePairDTO>> {
    return this.NamesResponse(params).pipe(
      __map(_r => _r.body as Array<IdNamePairDTO>)
    );
  }

  /**
   * Gets tenant user Ids and Names for use in drop-downs
   * @param params The `UserService.NamesWithTenantUserIdParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `IncludeRoles`:
   *
   * - `IncludeDisabled`:
   *
   * - `ExcludeRoles`:
   *
   * - `ExcludeDriverOnly`:
   *
   * @return Success - Returns user Id and Name
   */
  NamesWithTenantUserIdResponse(params: UserService.NamesWithTenantUserIdParams): __Observable<__StrictHttpResponse<Array<IdNamePairDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.IncludeRoles || []).forEach(val => {if (val != null) __params = __params.append('IncludeRoles', val.toString())});
    if (params.IncludeDisabled != null) __params = __params.set('IncludeDisabled', params.IncludeDisabled.toString());
    (params.ExcludeRoles || []).forEach(val => {if (val != null) __params = __params.append('ExcludeRoles', val.toString())});
    if (params.ExcludeDriverOnly != null) __params = __params.set('ExcludeDriverOnly', params.ExcludeDriverOnly.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/TenantUserNames`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IdNamePairDTO>>;
      })
    );
  }
  /**
   * Gets tenant user Ids and Names for use in drop-downs
   * @param params The `UserService.NamesWithTenantUserIdParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `IncludeRoles`:
   *
   * - `IncludeDisabled`:
   *
   * - `ExcludeRoles`:
   *
   * - `ExcludeDriverOnly`:
   *
   * @return Success - Returns user Id and Name
   */
  NamesWithTenantUserId(params: UserService.NamesWithTenantUserIdParams): __Observable<Array<IdNamePairDTO>> {
    return this.NamesWithTenantUserIdResponse(params).pipe(
      __map(_r => _r.body as Array<IdNamePairDTO>)
    );
  }

  /**
   * Information on the current user. No permission requirements.
   * @param Authorization Bearer {token}
   * @return OK
   */
  MeResponse(Authorization: string): __Observable<__StrictHttpResponse<UserDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/Me`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDTO>;
      })
    );
  }
  /**
   * Information on the current user. No permission requirements.
   * @param Authorization Bearer {token}
   * @return OK
   */
  Me(Authorization: string): __Observable<UserDTO> {
    return this.MeResponse(Authorization).pipe(
      __map(_r => _r.body as UserDTO)
    );
  }

  /**
   * Enables a user for the tenant
   * @param params The `UserService.EnableUserParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  EnableUserResponse(params: UserService.EnableUserParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/${encodeURIComponent(String(params.id))}/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Enables a user for the tenant
   * @param params The `UserService.EnableUserParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  EnableUser(params: UserService.EnableUserParams): __Observable<null> {
    return this.EnableUserResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Disable a user for the tenant
   * @param params The `UserService.DisableUserParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  DisableUserResponse(params: UserService.DisableUserParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/${encodeURIComponent(String(params.id))}/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Disable a user for the tenant
   * @param params The `UserService.DisableUserParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  DisableUser(params: UserService.DisableUserParams): __Observable<null> {
    return this.DisableUserResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Re-Sends the user a confirmation code to be used with the VERIFY_EMAIL challenge
   * @param Authorization Bearer {token}
   */
  VerifyEmailResponse(Authorization: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/VerifyEmail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Re-Sends the user a confirmation code to be used with the VERIFY_EMAIL challenge
   * @param Authorization Bearer {token}
   */
  VerifyEmail(Authorization: string): __Observable<null> {
    return this.VerifyEmailResponse(Authorization).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * If user changes their email, they will get a code.
   * Must call this method with code to confirm users email.
   * @param params The `UserService.ConfirmVerifyEmailParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  ConfirmVerifyEmailResponse(params: UserService.ConfirmVerifyEmailParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/ConfirmVerifyEmail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * If user changes their email, they will get a code.
   * Must call this method with code to confirm users email.
   * @param params The `UserService.ConfirmVerifyEmailParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  ConfirmVerifyEmail(params: UserService.ConfirmVerifyEmailParams): __Observable<null> {
    return this.ConfirmVerifyEmailResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Resets the lockout flag and FailedLogins.
   * @param params The `UserService.ResetLockoutParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `id`:
   */
  ResetLockoutResponse(params: UserService.ResetLockoutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/ResetLockout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Resets the lockout flag and FailedLogins.
   * @param params The `UserService.ResetLockoutParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `id`:
   */
  ResetLockout(params: UserService.ResetLockoutParams): __Observable<null> {
    return this.ResetLockoutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get a list of attachments for the user
   * @param params The `UserService.GetAttachmentsParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetAttachmentsResponse(params: UserService.GetAttachmentsParams): __Observable<__StrictHttpResponse<Array<TenantUserAttachmentDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/${encodeURIComponent(String(params.userId))}/attachments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TenantUserAttachmentDTO>>;
      })
    );
  }
  /**
   * Get a list of attachments for the user
   * @param params The `UserService.GetAttachmentsParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetAttachments(params: UserService.GetAttachmentsParams): __Observable<Array<TenantUserAttachmentDTO>> {
    return this.GetAttachmentsResponse(params).pipe(
      __map(_r => _r.body as Array<TenantUserAttachmentDTO>)
    );
  }

  /**
   * Upload a user attachment
   * @param params The `UserService.UploadAttachmentParams` containing the following parameters:
   *
   * - `userId`: Valid user id
   *
   * - `Authorization`: Bearer {token}
   *
   * - `fileType`: Type of file being uploaded
   *
   * - `file`: The upload
   *
   * - `expiresOn`: Optional: Indicate when a document expires (such as a license or certificate)
   *
   * @return Created
   */
  UploadAttachmentResponse(params: UserService.UploadAttachmentParams): __Observable<__StrictHttpResponse<TenantUserAttachmentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.fileType != null) __params = __params.set('fileType', params.fileType.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.expiresOn != null) __params = __params.set('expiresOn', params.expiresOn.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/${encodeURIComponent(String(params.userId))}/attachments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantUserAttachmentDTO>;
      })
    );
  }
  /**
   * Upload a user attachment
   * @param params The `UserService.UploadAttachmentParams` containing the following parameters:
   *
   * - `userId`: Valid user id
   *
   * - `Authorization`: Bearer {token}
   *
   * - `fileType`: Type of file being uploaded
   *
   * - `file`: The upload
   *
   * - `expiresOn`: Optional: Indicate when a document expires (such as a license or certificate)
   *
   * @return Created
   */
  UploadAttachment(params: UserService.UploadAttachmentParams): __Observable<TenantUserAttachmentDTO> {
    return this.UploadAttachmentResponse(params).pipe(
      __map(_r => _r.body as TenantUserAttachmentDTO)
    );
  }

  /**
   * User attachment detail
   * @param params The `UserService.DownloadAttachmentParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The requested user attachment
   */
  DownloadAttachmentResponse(params: UserService.DownloadAttachmentParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/attachments/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * User attachment detail
   * @param params The `UserService.DownloadAttachmentParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The requested user attachment
   */
  DownloadAttachment(params: UserService.DownloadAttachmentParams): __Observable<Blob> {
    return this.DownloadAttachmentResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Soft-delete the resource
   * @param params The `UserService.DeleteAttachmentParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  DeleteAttachmentResponse(params: UserService.DeleteAttachmentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/User/attachments/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Soft-delete the resource
   * @param params The `UserService.DeleteAttachmentParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  DeleteAttachment(params: UserService.DeleteAttachmentParams): __Observable<null> {
    return this.DeleteAttachmentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Sends attachments to email recipients
   * @param params The `UserService.SendAttachmentParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  SendAttachmentResponse(params: UserService.SendAttachmentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/attachments/send`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Sends attachments to email recipients
   * @param params The `UserService.SendAttachmentParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  SendAttachment(params: UserService.SendAttachmentParams): __Observable<null> {
    return this.SendAttachmentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UserService {

  /**
   * Parameters for Post
   */
  export interface PostParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: UserDTO;
  }

  /**
   * Parameters for GetUserById
   */
  export interface GetUserByIdParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for Put
   */
  export interface PutParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: UserDTO;
  }

  /**
   * Parameters for Names
   */
  export interface NamesParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    IncludeRoles?: Array<string>;
    IncludeDisabled?: boolean;
    ExcludeRoles?: Array<string>;
    ExcludeDriverOnly?: boolean;
  }

  /**
   * Parameters for NamesWithTenantUserId
   */
  export interface NamesWithTenantUserIdParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    IncludeRoles?: Array<string>;
    IncludeDisabled?: boolean;
    ExcludeRoles?: Array<string>;
    ExcludeDriverOnly?: boolean;
  }

  /**
   * Parameters for EnableUser
   */
  export interface EnableUserParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for DisableUser
   */
  export interface DisableUserParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for ConfirmVerifyEmail
   */
  export interface ConfirmVerifyEmailParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: VerifyEmailDTO;
  }

  /**
   * Parameters for ResetLockout
   */
  export interface ResetLockoutParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    id?: number;
  }

  /**
   * Parameters for GetAttachments
   */
  export interface GetAttachmentsParams {
    userId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for UploadAttachment
   */
  export interface UploadAttachmentParams {

    /**
     * Valid user id
     */
    userId: number;

    /**
     * Bearer {token}
     */
    Authorization: string;

    /**
     * Type of file being uploaded
     */
    fileType?: 'Other' | 'License' | 'LongMedicalForm' | 'DriverPay' | 'DrugTestingEnrollment' | 'CertificateOfCompliance' | 'NonMotorCarrierActivityForm' | 'ProofOfReceivingFMCSRHandbook' | 'EmploymentApplication' | 'MedicalExaminersCertificate' | 'PreEmploymentDrugTest' | 'RoadTest' | 'ChecklistForCasuals' | 'InquiryToPreviousEmployers' | 'InquiryToStateAgencies' | 'AnnualCertificationOfViolations' | 'AnnualMVRReview' | 'HazmatCertification';

    /**
     * The upload
     */
    file?: Blob;

    /**
     * Optional: Indicate when a document expires (such as a license or certificate)
     */
    expiresOn?: string;
  }

  /**
   * Parameters for DownloadAttachment
   */
  export interface DownloadAttachmentParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for DeleteAttachment
   */
  export interface DeleteAttachmentParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for SendAttachment
   */
  export interface SendAttachmentParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: SendFileDTO;
  }
}

export { UserService }
