/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Operation } from '../models/operation';
@Injectable({
  providedIn: 'root',
})
class LogisticsTenantService extends __BaseService {
  static readonly PatchPath = '/api/LogisticsTenant';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Update one or more properties of an existing Logistics Tenant
   * @param params The `LogisticsTenantService.PatchParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  PatchResponse(params: LogisticsTenantService.PatchParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/LogisticsTenant`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update one or more properties of an existing Logistics Tenant
   * @param params The `LogisticsTenantService.PatchParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  Patch(params: LogisticsTenantService.PatchParams): __Observable<null> {
    return this.PatchResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module LogisticsTenantService {

  /**
   * Parameters for Patch
   */
  export interface PatchParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: Array<Operation>;
  }
}

export { LogisticsTenantService }
