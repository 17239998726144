/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PlatformNotificationDTO } from '../models/platform-notification-dto';
import { PlatformNotificationTypeDTO } from '../models/platform-notification-type-dto';
@Injectable({
  providedIn: 'root',
})
class PlatformNotificationService extends __BaseService {
  static readonly GetNotificationsPath = '/api/Notifications';
  static readonly CreateNotificationPath = '/api/Notifications';
  static readonly UpdateNotificationPath = '/api/Notifications';
  static readonly GetNotificationByIdPath = '/api/Notifications/{id}';
  static readonly DeleteNotificationPath = '/api/Notifications/{id}';
  static readonly GetNotificationTypesPath = '/api/Notifications/Types';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets all the notifications
   * This call is used for the System Admin's notification table
   * @param params The `PlatformNotificationService.GetNotificationsParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `StatusEquals`:
   *
   * @return List of Notifications
   */
  GetNotificationsResponse(params: PlatformNotificationService.GetNotificationsParams): __Observable<__StrictHttpResponse<Array<PlatformNotificationDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.StatusEquals != null) __params = __params.set('StatusEquals', params.StatusEquals.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Notifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PlatformNotificationDTO>>;
      })
    );
  }
  /**
   * Gets all the notifications
   * This call is used for the System Admin's notification table
   * @param params The `PlatformNotificationService.GetNotificationsParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `StatusEquals`:
   *
   * @return List of Notifications
   */
  GetNotifications(params: PlatformNotificationService.GetNotificationsParams): __Observable<Array<PlatformNotificationDTO>> {
    return this.GetNotificationsResponse(params).pipe(
      __map(_r => _r.body as Array<PlatformNotificationDTO>)
    );
  }

  /**
   * Create a notification
   * @param params The `PlatformNotificationService.CreateNotificationParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return The created object
   */
  CreateNotificationResponse(params: PlatformNotificationService.CreateNotificationParams): __Observable<__StrictHttpResponse<PlatformNotificationDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Notifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PlatformNotificationDTO>;
      })
    );
  }
  /**
   * Create a notification
   * @param params The `PlatformNotificationService.CreateNotificationParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   *
   * @return The created object
   */
  CreateNotification(params: PlatformNotificationService.CreateNotificationParams): __Observable<PlatformNotificationDTO> {
    return this.CreateNotificationResponse(params).pipe(
      __map(_r => _r.body as PlatformNotificationDTO)
    );
  }

  /**
   * Updates the notification record
   * @param params The `PlatformNotificationService.UpdateNotificationParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The updated notification
   */
  UpdateNotificationResponse(params: PlatformNotificationService.UpdateNotificationParams): __Observable<__StrictHttpResponse<PlatformNotificationDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Notifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PlatformNotificationDTO>;
      })
    );
  }
  /**
   * Updates the notification record
   * @param params The `PlatformNotificationService.UpdateNotificationParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return The updated notification
   */
  UpdateNotification(params: PlatformNotificationService.UpdateNotificationParams): __Observable<PlatformNotificationDTO> {
    return this.UpdateNotificationResponse(params).pipe(
      __map(_r => _r.body as PlatformNotificationDTO)
    );
  }

  /**
   * Gets a notification by id
   * @param params The `PlatformNotificationService.GetNotificationByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return List of Notifications
   */
  GetNotificationByIdResponse(params: PlatformNotificationService.GetNotificationByIdParams): __Observable<__StrictHttpResponse<PlatformNotificationDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Notifications/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PlatformNotificationDTO>;
      })
    );
  }
  /**
   * Gets a notification by id
   * @param params The `PlatformNotificationService.GetNotificationByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return List of Notifications
   */
  GetNotificationById(params: PlatformNotificationService.GetNotificationByIdParams): __Observable<PlatformNotificationDTO> {
    return this.GetNotificationByIdResponse(params).pipe(
      __map(_r => _r.body as PlatformNotificationDTO)
    );
  }

  /**
   * @param params The `PlatformNotificationService.DeleteNotificationParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  DeleteNotificationResponse(params: PlatformNotificationService.DeleteNotificationParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Notifications/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PlatformNotificationService.DeleteNotificationParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   */
  DeleteNotification(params: PlatformNotificationService.DeleteNotificationParams): __Observable<null> {
    return this.DeleteNotificationResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Gets all notification types
   * @param Authorization Bearer {token}
   * @return List of Notification types
   */
  GetNotificationTypesResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<PlatformNotificationTypeDTO>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Notifications/Types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PlatformNotificationTypeDTO>>;
      })
    );
  }
  /**
   * Gets all notification types
   * @param Authorization Bearer {token}
   * @return List of Notification types
   */
  GetNotificationTypes(Authorization: string): __Observable<Array<PlatformNotificationTypeDTO>> {
    return this.GetNotificationTypesResponse(Authorization).pipe(
      __map(_r => _r.body as Array<PlatformNotificationTypeDTO>)
    );
  }
}

module PlatformNotificationService {

  /**
   * Parameters for GetNotifications
   */
  export interface GetNotificationsParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    StatusEquals?: 'Active' | 'Pending' | 'Expired' | 'All';
  }

  /**
   * Parameters for CreateNotification
   */
  export interface CreateNotificationParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: PlatformNotificationDTO;
  }

  /**
   * Parameters for UpdateNotification
   */
  export interface UpdateNotificationParams {
    body: PlatformNotificationDTO;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for GetNotificationById
   */
  export interface GetNotificationByIdParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for DeleteNotification
   */
  export interface DeleteNotificationParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }
}

export { PlatformNotificationService }
