/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TenantDTO } from '../models/tenant-dto';
import { NoteDTO } from '../models/note-dto';
@Injectable({
  providedIn: 'root',
})
class TenantService extends __BaseService {
  static readonly GetPath = '/api/Tenant';
  static readonly PutPath = '/api/Tenant/{id}';
  static readonly PaymentTermOptionsPath = '/api/Tenant/PaymentTermOptions';
  static readonly GetTenantNotesPath = '/api/Tenant/notes/{noteType}';
  static readonly SetTenantDispatchNotesPath = '/api/Tenant/notes';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get current tenant
   * @param Authorization Bearer {token}
   * @return Success
   */
  GetResponse(Authorization: string): __Observable<__StrictHttpResponse<TenantDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Tenant`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantDTO>;
      })
    );
  }
  /**
   * Get current tenant
   * @param Authorization Bearer {token}
   * @return Success
   */
  Get(Authorization: string): __Observable<TenantDTO> {
    return this.GetResponse(Authorization).pipe(
      __map(_r => _r.body as TenantDTO)
    );
  }

  /**
   * Update current tenant
   * @param params The `TenantService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  PutResponse(params: TenantService.PutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Tenant/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update current tenant
   * @param params The `TenantService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  Put(params: TenantService.PutParams): __Observable<null> {
    return this.PutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns the payment term options for the tenant
   * @param Authorization Bearer {token}
   * @return Success
   */
  PaymentTermOptionsResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Tenant/PaymentTermOptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Returns the payment term options for the tenant
   * @param Authorization Bearer {token}
   * @return Success
   */
  PaymentTermOptions(Authorization: string): __Observable<Array<string>> {
    return this.PaymentTermOptionsResponse(Authorization).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * Gets notes related to a tenant
   * @param params The `TenantService.GetTenantNotesParams` containing the following parameters:
   *
   * - `noteType`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetTenantNotesResponse(params: TenantService.GetTenantNotesParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Tenant/notes/${encodeURIComponent(String(params.noteType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Gets notes related to a tenant
   * @param params The `TenantService.GetTenantNotesParams` containing the following parameters:
   *
   * - `noteType`:
   *
   * - `Authorization`: Bearer {token}
   *
   * @return Success
   */
  GetTenantNotes(params: TenantService.GetTenantNotesParams): __Observable<string> {
    return this.GetTenantNotesResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Set Tenant notes
   * @param params The `TenantService.SetTenantDispatchNotesParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  SetTenantDispatchNotesResponse(params: TenantService.SetTenantDispatchNotesParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Tenant/notes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Set Tenant notes
   * @param params The `TenantService.SetTenantDispatchNotesParams` containing the following parameters:
   *
   * - `Authorization`: Bearer {token}
   *
   * - `body`:
   */
  SetTenantDispatchNotes(params: TenantService.SetTenantDispatchNotesParams): __Observable<null> {
    return this.SetTenantDispatchNotesResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TenantService {

  /**
   * Parameters for Put
   */
  export interface PutParams {
    id: number;

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: TenantDTO;
  }

  /**
   * Parameters for GetTenantNotes
   */
  export interface GetTenantNotesParams {
    noteType: string;

    /**
     * Bearer {token}
     */
    Authorization: string;
  }

  /**
   * Parameters for SetTenantDispatchNotes
   */
  export interface SetTenantDispatchNotesParams {

    /**
     * Bearer {token}
     */
    Authorization: string;
    body?: NoteDTO;
  }
}

export { TenantService }
